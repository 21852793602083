import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common'
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Router, RouterEvent } from '@angular/router'
import { RufDynamicSidemenuItem } from '@ruf/shell'
import { sortBy } from 'lodash'
import { Subscription } from 'rxjs'
import { AppService } from './app.service'
import { MODULE_IN_MENU_ORDER_BY_MODULE_PREFIX_MAP } from './common/model/app.constants'
import { ModuleConfigService } from './common/services/module-config.service'
import { AdminUiAuthService } from './service-api/admi-ui-auth.service'
import { GUIDED_JOURNEY_BREADCRUMB } from './shared/messages.constants'
import { getAppVersion } from './shared/utils/app.utils'

type MenuItemType = RufDynamicSidemenuItem & { orderNo: number }

@Component({
  selector: 'digital-platform-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
})
export class AppComponent implements OnInit, OnDestroy {
  showPreview: Subscription
  showPreviewResult = 0
  userInitials: string
  fixed = false
  coverHeader = false
  showHeader = false
  showFooter = false
  modeIndex = 0
  dialogRef: MatDialogRef<any>
  hasBackdrop = false
  showFiller = false
  minimized = false
  loadComp = GUIDED_JOURNEY_BREADCRUMB
  @ViewChild('myTemplate') customTemplate: TemplateRef<any>
  selectedPath = ''
  moduleLoadingSubscription: Subscription
  routerEventSubscription: Subscription

  items: MenuItemType[] = [
    {
      orderNo: 20, // put to the end, ensure to be at least equal to number of mudules
      label: 'Migrate Data',
      path: 'migrate-data',
      icon: 'fis-icon-export',
    },
  ]
  showCurrentYear: number
  appVersion: string

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private appService: AppService,
    private adminUiAuthService: AdminUiAuthService
  ) {
    this.moduleLoadingSubscription = this.appService.subscribeToModuleLoading(this.moduleLoadingSubscribe.bind(this))

    this.showCurrentYear = new Date().getUTCFullYear()
    this.appVersion = getAppVersion()
  }

  ngOnInit(): void {
    const userDetails = this.adminUiAuthService.getLoggedInUser()
    this.userInitials = `${userDetails?.FirstName?.charAt(0)}${userDetails?.LastName?.charAt(0)}`.toUpperCase()

    this.routerEventSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof RouterEvent) {
        if (this.selectedPath !== event.url) {
          this.selectedPath = event.url?.split('?')[0]
        }
      }
    })
  }

  get mode() {
    return ['over'][this.modeIndex]
  }

  get fixedTop() {
    return this.fixed && this.showHeader && !this.coverHeader ? 64 : 0
  }

  get fixedBottom() {
    return this.fixed && this.showFooter && !this.coverHeader ? 64 : 0
  }

  moduleLoadingSubscribe(moduleConfigService: ModuleConfigService): void {
    const mNavMeta = moduleConfigService.getModuleNavigationMeta()
    this.items.splice(this.items.length > 0 ? this.items.length - 1 : 0, 0, {
      orderNo: MODULE_IN_MENU_ORDER_BY_MODULE_PREFIX_MAP[moduleConfigService.getModulePrefix()],
      label: mNavMeta?.moduleTitle,
      path: moduleConfigService.getModulePrefix(),
      icon: mNavMeta?.moduleIcon || 'fis-icon-chat',
      children: [
        { label: mNavMeta?.homeUrlTitle, path: mNavMeta?.homeUrl },
        { label: mNavMeta?.historyUrlTitle, path: mNavMeta?.historyUrl },
      ],
    })

    this.items = sortBy([...this.items], ['orderNo'])
  }

  open(path: string) {
    this.router.navigate([path])
  }

  sideMenuSelected(path: string) {
    this.router.navigate([path])
  }

  ngOnDestroy() {
    const subscriptions = [this.showPreview]
    for (let i = 0; i < subscriptions.length; i++) {
      if (subscriptions[i]) {
        subscriptions[i].unsubscribe()
      }
    }
    this.moduleLoadingSubscription.unsubscribe()
    this.routerEventSubscription.unsubscribe()
  }
}
