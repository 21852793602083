// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.

import { RufAuthConfig } from '@ruf/idp-auth';

// The list of file replacements can be found in `angular.json`.
const baseApiUrl = 'https://gsc-admin-uat.fis.app/api'
const baseUrl = 'https://gsc-admin-uat.fis.app'

export const environment: { production: boolean; apis: any; auth: RufAuthConfig } = {
  production: false,
  apis: {
    gscApiUrl: `${baseApiUrl}/gsc`,
    itdpApiUrl: `${baseApiUrl}/itdp`,
    gbtApiUrl: `${baseApiUrl}/gbt`,
    resBApiUrl: `${baseApiUrl}/resb`,
    fileApiUrl: `${baseApiUrl}/file`,
    ctaApiUrl: `${baseApiUrl}/cta`,
    ctaSnowApiUrl: `${baseApiUrl}/cta-snow`,
    keywordDetectionApiUrl: `${baseApiUrl}/keyword-detection`,
    versionlogApiUrl: `${baseApiUrl}/versionlog`,
    sdmApiUrl: `${baseApiUrl}/sdm`,
    callSupportApiUrl: `${baseApiUrl}/call-support`,
    uploadObjectFileUrl: `${baseApiUrl}/object-file-import`,
    quickLinksApiUrl: `${baseApiUrl}/quick-links`,
    recommendationsApiUrl: `${baseApiUrl}/recommendations`,
    tourGuideApiUrl: `${baseApiUrl}/tour-guide`,
    megamenuApiUrl: `${baseApiUrl}/mega-menu`,
    webhookUrl: `${baseApiUrl}/webhook`,
  },
  auth: {
    issuer: 'https://login-uat.fisglobal.com/idp/eloginuat',
    clientId: 'eto-gsc-adminui-azure-uat',
    dummyClientSecret: 'c9c87638024ac04fbc0920409acd9616d748',
    redirectUri: `${baseUrl}`,
    logoutUrl: `${baseUrl}/logout`,
    requireHttps: false,
    responseType: 'code',
    autoRefresh: true,
    listenToToken: 'id_token',
    skipIssuerCheck: true,
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
