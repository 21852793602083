import { Delta } from "jsondiffpatch";
import { fromPath, isNumber } from "./common.uitils";

const FIELDS_TO_SKIP = ['_t']
export class DeltaUtils {
  static getDeletedNodesByPath(delta: Delta | undefined): { [key: string]: any } | undefined {
    const result: { [key: string]: any } = {}
    const path: string[] = []

    this._getDeletedNodesByPath(delta, result, path)

    return result
  }

  static _getDeletedNodesByPath(delta: Delta | undefined, result: any, path: string[]): { [key: string]: any } | undefined {
    if (!delta) {
      return undefined
    }

    for (const key of Object.keys(delta)) {
      if (!FIELDS_TO_SKIP.includes(key)) {
        if (key.startsWith('_') && isNumber(key.substring(1))) {
          result[`${fromPath([...path, key.slice(1, key.length)])}`] = delta[key] instanceof Array && delta[key].length ? delta[key][0] : delta[key]
        } else {
          if (delta[key] && typeof delta[key] === 'object') {
            path.push(key)
            this._getDeletedNodesByPath(delta[key], result, path)
          }
        }
      }
    }

    path.pop()
    return result
  }
}