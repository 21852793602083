import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"
import { JSOCompareConstants } from '@digital-platform/json-compare'
import { forEach, size } from "lodash"
import { flattenByKey } from "./collection.utils"

export const getSlugValidator = (dataSource: any[], predicate?: any, additionalInvalidSlugs?: string[]): ValidatorFn => {
  const predicateFn = predicate || (() => true)
  const slugs = new Set(flattenByKey(dataSource, JSOCompareConstants.DEFAULT_CHILDREN_NODE_NAME).filter(predicateFn).map(item => item.slug))
  if (size(additionalInvalidSlugs) > 0) {
    forEach(additionalInvalidSlugs, slug => slugs.add(slug))
  }

  return (control: AbstractControl): ValidationErrors => {
    return slugs.has(control.value) ? { notUnique: true } : null
  }
}