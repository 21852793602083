import { size } from "lodash";

const _flattenByKey = (dataSource: any, key: string, result: any): void => {
    dataSource?.forEach(element => {
        if (!element) {
            return
        }
        result.push(element);
        if (size(element[key]) > 0) {
            _flattenByKey(element[key], key, result);
        }
    });
}

export const flattenByKey = (dataSource: any, key: string, rootNotInclude?: boolean): any[] => {
    const result: any = []

    let root = dataSource
    if (rootNotInclude) {
        // this is for one element array that does not inslucde root object
        if (dataSource instanceof Array && size(dataSource) === 1) {
            root = dataSource[0][key]
        }
    }

    _flattenByKey(root, key, result)

    return result
}