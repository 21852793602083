import { Component, Input, OnChanges, OnInit, Optional, SimpleChanges, ViewContainerRef } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { Subject } from 'rxjs'
import { TreeNodeEditActions } from '../../../shared/tree-node-edit.actions'
import { FormBuilderService } from '../../services/form-builder.service'
import { ObservableDataHolder } from '../../utils/observalbe-data-holder'
import { AddNodeFormBuilderService } from './add-node-form-builder.service'

@Component({
  selector: 'digital-platform-questionnaire-add-node',
  templateUrl: './questionnaire-add-node.component.html',
  styleUrls: ['./questionnaire-add-node.component.scss'],
})
export class QuestionnaireAddComponent implements ObservableDataHolder, OnInit, OnChanges {
  form = new UntypedFormGroup({})
  fields: FormlyFieldConfig[] = []
  model: any
  actionSubscription = new Subject()

  @Input()
  data: {
    treeData: any
    additionalInvalidSlugs: string[]
    formBuilderService: FormBuilderService
    addNodeFormBuilderService: AddNodeFormBuilderService
    path: string
  }

  constructor(
    @Optional() public formBuilderService: FormBuilderService,
    @Optional() private addNodeFormBuilderService: AddNodeFormBuilderService
  ) {}

  ngOnInit(): void {
    if (!this.formBuilderService) {
      this.formBuilderService = this.data?.formBuilderService
    }

    if (this.data?.addNodeFormBuilderService) {
      this.addNodeFormBuilderService = this.data.addNodeFormBuilderService
    }

    this.addNodeFormBuilderService?.onPostInit(this)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.addNodeFormBuilderService?.onPostChanges(this)
    }
  }

  cancel() {
    this.actionSubscription.next({ action: TreeNodeEditActions.CLOSE })
  }

  save() {
    this.actionSubscription.next({ action: TreeNodeEditActions.SAVE, node: this.model, formValid: this.form.valid })
    if (!this.form.valid) {
      this.form.markAllAsTouched()
      this.form.updateValueAndValidity()
    }
  }

  getObservable() {
    return this.actionSubscription.asObservable()
  }
}
