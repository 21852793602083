import { forwardRef, Inject, Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router"
import { Observable } from "rxjs"
import { AdminUiAuthService } from "../service-api/admi-ui-auth.service"

@Injectable()
export class GscModuleLoadingGuard implements CanActivate {
  constructor(
    @Inject(forwardRef(() => AdminUiAuthService)) private adminUiAuthService: AdminUiAuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return true
  }
}