export default {
  textDiff: {
    // setting this config to prevent diff on strings
    minLength: Number.MAX_VALUE,
  },
  // used to match objects when diffing arrays, by default only === operator is used
  objectHash: function (obj: any) {
    // this function is used only to when objects are not equal by ref
    return obj.__nodeId
  },
  arrays: {
    // default true, detect items moved inside the array (otherwise they will be registered as remove+add)
    detectMove: false,
    // default false, the value of items moved is not included in deltas
    includeValueOnMove: false,
  }
}