import {
  Component,
  ViewEncapsulation,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'digital-platform-progress-tile',
  templateUrl: './progress-tile.component.html',
  styleUrls: ['./progress-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DemoProgressTileComponent {

  @HostBinding('class.digital-platform-progress-tile') progressTileCls = true;

}
