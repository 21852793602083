import { ModuleNames } from './constants'

export const MODULE_IN_MENU_ORDER_BY_MODULE_PREFIX_MAP = {
  [ModuleNames.GSC]: 0,
  [ModuleNames.ITDP]: 1,
  [ModuleNames.GBT]: 2,
  [ModuleNames.RESB]: 3,
  [ModuleNames.CTA]: 4,
  [ModuleNames.KEYWORD_DETECTION]: 5,
  [ModuleNames.VERSIONLOG]: 6,
  [ModuleNames.CALL_SUPPORT]: 7,
  [ModuleNames.QUICK_LINKS]: 8,
  [ModuleNames.SDM]: 9,
  [ModuleNames.RECOMMENDATIONS]: 10,
  [ModuleNames.TOUR_GOUDE]: 11,
  [ModuleNames.MEGAMENU]: 12,
  [ModuleNames.WEBHOOK]: 13,
}
