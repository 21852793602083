import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations' // Don't like animations? Replace this with NoopAnimationsModule
import { FileSaverModule } from 'ngx-filesaver'

import {
  RufAppCanvasModule,
  RufBannerModule,
  RufCardModule,
  RufDialogPanelModule,
  RufDropdownPanelModule,
  RufDynamicMenubarModule,
  RufDynamicSegmentModule,
  RufDynamicSideMenuModule,
  RufFooterModule,
  RufIconModule,
  RufLabeledIconModule,
  RufLayoutModule,
  RufMenubarModule,
  RufNavbarModule,
  RufPageHeaderModule,
  RufSidemenuModule,
  RufStatusbarModule,
  RufTriggerModule,
} from '@ruf/shell'

import { DragDropModule } from '@angular/cdk/drag-drop'
import { OverlayModule } from '@angular/cdk/overlay'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatMomentDateModule } from '@angular/material-moment-adapter'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatTreeModule } from '@angular/material/tree'
import { CustomTemplatesFormlyModule } from '@digital-platform/custom-templates-formly'
import { AuthModule, TokenStoreStorage } from '@ruf/idp-auth'
import { RufMaterialModule } from '@ruf/material'
import {
  RufDynamicDatatableModule,
  RufFileUploadModule,
  RufLoadingIndicatorModule,
  RufNativeDateTimeModule,
  RufStepperModule,
  RufTimePickerModule,
} from '@ruf/widgets'
import { CookieService } from 'ngx-cookie-service'
import { environment } from '../environments/environment'
import { AdminuiHomeComponent } from './adminui-home/adminui-home.component'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AppService } from './app.service'
import { CommonModule as AdminUiCommonModule } from './common/common.module'
import { HomePreviewContentComponent } from './common/components/home-preview-content/home-preview-content'
import { SpinnerService } from './common/spinner-overlay/spinner-overlay.service'
import { AdminUiAuthService } from './service-api/admi-ui-auth.service'
import { UploadService } from './service-api/upload.service'
import { HttpErrorInterceptor } from './shared/http-error.interceptor'
import { JsonCompareDialogComponent } from './shared/json-compare/json-compare-dialog/json-compare-dialog.component'
import { JsonViewComponent } from './shared/json-compare/json-view/json-view.component'
import { MigrateDataComponent } from './shared/migrate-data/migrate-data.component'
import { DemoProgressTileComponent } from './shared/migrate-data/progress-tile/progress-tile.component'
import { SelectModuleDropdownComponent } from './shared/select-module-dropdown/select-module-dropdown.component'
import { StatusbarComponent } from './shared/statusbar/statusbar.component'
import { SideMenuComponent } from './side-menu/side-menu.component'
import { OAuthStorage } from 'angular-oauth2-oidc';
import { MyTokenStore } from './token.service';
@NgModule({
  declarations: [
    AppComponent,
    MigrateDataComponent,
    DemoProgressTileComponent,
    JsonViewComponent,
    JsonCompareDialogComponent,
    StatusbarComponent,
    HomePreviewContentComponent,
    SideMenuComponent,
    SelectModuleDropdownComponent,
    AdminuiHomeComponent,
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    MatIconModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RufMaterialModule,
    RufAppCanvasModule,
    FormsModule,
    RufLayoutModule,
    RufBannerModule,
    RufFooterModule,
    RufIconModule,
    RufMenubarModule,
    RufNavbarModule,
    RufPageHeaderModule,
    HttpClientModule,
    RufDynamicSideMenuModule,
    RufLabeledIconModule,
    MatButtonModule,
    RufDynamicMenubarModule,
    FormsModule,
    RufMaterialModule,
    ReactiveFormsModule,
    MatInputModule,
    MatMenuModule,
    RufDialogPanelModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    RufDynamicDatatableModule,
    RufDropdownPanelModule,
    RufTriggerModule,
    OverlayModule,
    MatTooltipModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    RufDynamicSegmentModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RufTimePickerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    RufLoadingIndicatorModule,
    MatStepperModule,
    RufStepperModule,
    MatCheckboxModule,
    CustomTemplatesFormlyModule,
    MatListModule,
    MatSidenavModule,
    RufSidemenuModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatRadioModule,
    RufFileUploadModule,
    RufStatusbarModule,
    MatProgressBarModule,
    RufNativeDateTimeModule,
    RufCardModule,
    FileSaverModule,
    AuthModule.forRoot({ ...environment.auth }),
    AdminUiCommonModule,
    DragDropModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    // {
    //   provide: OAuthStorage,
    //   useClass: MyTokenStore,
    // },
    CookieService,
    SpinnerService,
    UploadService,
    AdminUiAuthService,
    { provide: AppService, deps: [AdminUiAuthService] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}