import { Injectable } from "@angular/core"
import { PreloadingStrategy, Route } from "@angular/router"
import { UserRoles } from "@digital-platform/model"
import { EMPTY, Observable, switchMap } from "rxjs"
import { ModuleNames } from "../common/model/constants"
import { AdminUiAuthService } from "../service-api/admi-ui-auth.service"

@Injectable()
export class RolePreloadingStrategy implements PreloadingStrategy {
  constructor(private adminUiAuthService: AdminUiAuthService) { }

  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    return this.adminUiAuthService.getLoadUserRoleObs().pipe(switchMap((roles: string[]) => {
      if (route.path !== ModuleNames.GBT || roles?.includes(UserRoles.GSC_ADMINUI_MODULE_GBT_USER)) {
        return fn()
      }

      return EMPTY
    }))
  }
}