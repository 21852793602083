import { Injectable } from "@angular/core"
import { isEmpty } from "lodash"
import { FileSaverService } from "ngx-filesaver"
import { lastValueFrom } from "rxjs"
import { UploadService } from "../../service-api/upload.service"

@Injectable()
export class FileObjectService {
  constructor(
    private fileService: UploadService,
    private ngxFileServerService: FileSaverService
  ) { }

  async export(type: string, filterValues: any): Promise<void> {
    const publishedDoc: any = await lastValueFrom(this.fileService.exportObjects(type, filterValues))
    this.saveAsJsonFile(publishedDoc, `${type}_export_${Date.now()}`)
  }

  saveAsJsonFile(data: any, filename: string): void {
    const blob = new Blob([JSON.stringify(data)], { type: "application/json" })
    this.ngxFileServerService.save(blob, filename)
  }

  saveAsCsvFile(data: any, filename: string): void {
    const blob = new Blob([this.convertToCSV(data)], { type: "text/csv" })
    this.ngxFileServerService.save(blob, filename)
  }

  async import(type: string, objects: any): Promise<any> {
    return await lastValueFrom(this.fileService.importObjects(type, objects))
  }

  convertToCSV(items: any[]): string {
    if (isEmpty(items)) {
      return ''
    }

    if (!(items instanceof Array)) {
      throw new Error('Invalid input object to convert to CSV')
    }

    const array = [Object.keys(items[0])].concat(items)
    return array.map(it => {
      return Object.values(it).toString()
    }).join('\r\n')
  }
}