import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '@ruf/idp-auth'
import { AccessGuard } from './access.guard'
import { AdminuiHomeComponent } from './adminui-home/adminui-home.component'
import { HomeComponentDeactivateGuard } from './common/guards/home-deactivate.guard'
import { CallSupportModuleLoadingGuard } from './module-loading-gurards/call-support-module-loading-guard'
import { CtaModuleLoadingGuard } from './module-loading-gurards/cta-module-loading-guard'
import { GbtModuleLoadingGuard } from './module-loading-gurards/gbt-module-loading-guard'
import { GscModuleLoadingGuard } from './module-loading-gurards/gsc-module-loading-guard'
import { ItdpModuleLoadingGuard } from './module-loading-gurards/itdp-module-loading-guard'
import { KeywordDetectionModuleLoadingGuard } from './module-loading-gurards/keyword-detection-module-loading-guard'
import { ResBModuleLoadingGuard } from './module-loading-gurards/resb-module-loading-guard'
import { RolePreloadingStrategy } from './module-loading-gurards/role-preloading-strategy'
import { SdmModuleLoadingGuard } from './module-loading-gurards/sdm-module-loading-guard'
import { VersionlogModuleLoadingGuard } from './module-loading-gurards/versionlog-module-loading-guard'
import { MigrateDataComponent } from './shared/migrate-data/migrate-data.component'
import { WebhookGuard } from './module-loading-gurards/webhook-guard'

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: 'home', component: AdminuiHomeComponent },
      { path: 'gsc', loadChildren: () => import('./gsc/gsc.module').then(modules => modules.GscModule), canActivate: [GscModuleLoadingGuard, WebhookGuard] },
      { path: 'itdp', loadChildren: () => import('./itdp/itdp.module').then(modules => modules.ItdpModule), canActivate: [ItdpModuleLoadingGuard, WebhookGuard] },
      { path: 'gbt', loadChildren: () => import('./gbt/gbt.module').then(modules => modules.GbtModule), canActivate: [GbtModuleLoadingGuard, WebhookGuard] },
      { path: 'resb', loadChildren: () => import('./resb/resb.module').then(modules => modules.ResBModule), canActivate: [ResBModuleLoadingGuard, WebhookGuard] },
      { path: 'cta', loadChildren: () => import('./cta/cta.module').then(modules => modules.CtaModule), canActivate: [CtaModuleLoadingGuard, WebhookGuard] },
      { path: 'keyword-detection', loadChildren: () => import('./keyword-detection/keyword-detection.module').then(modules => modules.KeywordDetectionModule), canActivate: [KeywordDetectionModuleLoadingGuard, WebhookGuard] },
      { path: 'call-support', loadChildren: () => import('./call-support/call-support.module').then(modules => modules.CallSupportModule), canActivate: [CallSupportModuleLoadingGuard, WebhookGuard] },
      { path: 'versionlog', loadChildren: () => import('./versionlog/versionlog.module').then(modules => modules.VersionlogModule), canActivate: [VersionlogModuleLoadingGuard, WebhookGuard] },
      { path: 'quick-links', loadChildren: () => import('./quick-links/quick-links.module').then(modules => modules.QuickLinksModule), canActivate: [WebhookGuard] },
      { path: 'migrate-data', component: MigrateDataComponent, canActivate: [AccessGuard, WebhookGuard] },
      { path: 'sdm', loadChildren: () => import('./sdm/sdm.module').then(modules => modules.SdmModule), canActivate: [SdmModuleLoadingGuard, WebhookGuard] },
      { path: 'recommendations', loadChildren: () => import('./recommendations/recommendations.module').then(modules => modules.RecommendationsModule), canActivate: [WebhookGuard] },
      { path: 'tour-guide', loadChildren: () => import('./tour-guide/tour-guide.module').then((modules) => modules.TourGuideModule), canActivate: [WebhookGuard] },
      { path: 'mega-menu', loadChildren: () => import('./mega-menu/mega-menu.module').then(modules => modules.MegamenuModule), canActivate: [WebhookGuard] },
      { path: 'webhook', loadChildren: () => import('./webhook/webhook.module').then(modules => modules.WebhookModule), canActivate: [WebhookGuard] },
      { path: '**', redirectTo: '/home' },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking', preloadingStrategy: RolePreloadingStrategy })],
  exports: [RouterModule],
  providers: [
    HomeComponentDeactivateGuard,
    RolePreloadingStrategy,
    GscModuleLoadingGuard,
    ItdpModuleLoadingGuard,
    GbtModuleLoadingGuard,
    ResBModuleLoadingGuard,
    CtaModuleLoadingGuard,
    KeywordDetectionModuleLoadingGuard,
    CallSupportModuleLoadingGuard,
    VersionlogModuleLoadingGuard,
    SdmModuleLoadingGuard,
    WebhookGuard
  ],
})
export class AppRoutingModule { }