import { isArray } from "lodash"

const pathToString = (array: any[]) => {
  return array.reduce((string, item) => {
    var prefix = string === '' ? '' : '.'
    return string + (isNaN(Number(item)) ? prefix + item : '[' + item + ']')
  }, '')
}

export const fromPath = (value: any): string => {
  if (isArray(value)) {
    return pathToString(value)
  }
  return ''
}

export const isNumber = (char: string) => /^[0-Number.MAX_VALUE]$/.test(char)