<ruf-dialog-panel [closeIconTitle]="'Close'">
  <ruf-dialog-header #header>Add New Node
    <hr class="admin-ui-divider">
  </ruf-dialog-header>
  <ruf-dialog-content rufScrollbar class="admin-add-node-dialog">
    <form [formGroup]="form" fxLayout="column">
      <formly-form [form]="form" [fields]="fields" [model]="model" name="form"></formly-form>
    </form>
  </ruf-dialog-content>
  <ruf-dialog-footer>
    <div class="button-group">
      <button type="button" (click)="cancel()" class="button-group-button" color="primary" fisStyle
        mat-stroked-button>Cancel</button>
      <button (click)="save()" color="primary" fisStyle mat-raised-button class="button-group-button">Save</button>
    </div>
  </ruf-dialog-footer>
</ruf-dialog-panel>