import { Component, QueryList, ViewChildren } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { RufDropdownPanelComponent, RufDropdownType } from '@ruf/shell';
@Component({
  selector: 'digital-platform-formly-wrapper-panel',
  template: `
<section class="example-section">
<label class="ruf-label">{{field.templateOptions?.label}}</label>
<mat-slide-toggle fisStyle rufId class="example-margin" [color]="color" (change)="changeToggleval($event)" [checked]="isChecked()" [formlyAttributes]="field">

   </mat-slide-toggle>
  
   <mat-icon  class="fis-icon-sm" fisStyle fontSet="fisfont" fontIcon="fis-icon-information" (mouseenter)="openTooltip(dropdownPanel)" [rufTrigger]="dropdownPanel" #trigger="rufTrigger" matPrefix>
   </mat-icon>
  
   <ruf-dropdown-panel class="trishta" [hasBackdrop]="hasBackdrop" #dropdownPanel [origin]="trigger" [type]="panel.type" xPosition="start" yPosition="bottom">
   <div class="popoverContent">
     <h3 style="position:relative; left:13px">Mandatory Selection</h3> 
     <pre style="position:relative; right:20px">
     Fields marked as mandatory, False in 
     SNOW can be set to True for the 
     purpose of this ticket</pre>
   </div>
 </ruf-dropdown-panel>
  </section>
`,
})
export class TooltipWithToggleComponent extends FieldWrapper {
  color = "primary";
  panel = {
    type: RufDropdownType.PopoverMenu
  };
  hasBackdrop = false;
  @ViewChildren('dropdownPanel')
  dropdownPanel!: QueryList<RufDropdownPanelComponent>;

  changeToggleval(data: any) {
    // @ts-ignore
    this.field.model[this.key] = data.checked;
  }

  openTooltip(element: any) {
    this.dropdownPanel.forEach((descriptionTooltip: any) => descriptionTooltip.close());
    element.open();
  }

  isChecked() {
    // @ts-ignore
    return this.field.model[this.key]
  }
}