import { Component } from '@angular/core'
import { FormlyFieldToggleComponent } from './toggle-template'

const VALUE_MAP = {
  true: 'branch',
  false: 'leaf',
}

@Component({
  selector: 'digital-platform-formly-field-input',
  styleUrls: ['./toggle-template.scss'],
  templateUrl: './toggle.html',
})
export class RufFormlyFieldToggleComponent extends FormlyFieldToggleComponent {
  changeToggleval(data: any) {
    // @ts-ignore
    this.field.formControl?.setValue(VALUE_MAP[data.checked])
  }

  isChecked() {
    // @ts-ignore
    return this.field.model[this.key] === VALUE_MAP.true
  }

  showValue(): boolean {
    return true
  }
}
