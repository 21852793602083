import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { JSOCompareConstants, addObjectAtPath, getFromObjectPath, idfyObject } from '@digital-platform/json-compare'
import { toPath } from 'lodash'
import { TreeNodeEditActions } from '../../shared/tree-node-edit.actions'
import { ObservableDialogService } from '../../shared/user-journey/service/dialog-observable.service'
import { AddNodeFormBuilderService } from '../components/add-node/add-node-form-builder.service'
import { QuestionnaireAddComponent } from '../components/add-node/questionnaire-add-node.component'
import { SimpleDialogComponent } from '../components/dialogs/simple-dialog/simple-dialog.component'
import { NODE_TYPE } from '../model/node-type'
import { flattenByKey } from '../utils/collection.utils'
import { AddNodeService } from './add-node.service'
import { FormBuilderService } from './form-builder.service'

@Injectable()
export class QuestionnaireAddNodeService implements AddNodeService {
  constructor(
    private matDialogService: MatDialog,
    private formBuilderService: FormBuilderService,
    private dialogService: ObservableDialogService,
    private addNodeFormBuilderService: AddNodeFormBuilderService
  ) {}

  async addNode(addData: any): Promise<void> {
    await this.addNodeLocal(addData)
  }

  async addNodeLocal(addData: any, parentNode?: any, rootParent?: any): Promise<void> {
    const { treeData, createNewCallback } = addData

    if (!rootParent) {
      rootParent = parentNode
    }

    let additionalInvalidSlugs: string[] = []
    if (parentNode) {
      additionalInvalidSlugs = flattenByKey([parentNode], JSOCompareConstants.DEFAULT_CHILDREN_NODE_NAME).map(
        (item) => item.slug
      )
    }

    const dialogConfig: MatDialogConfig = {
      role: 'dialog',
      panelClass: ['fis-style', 'overflow-hide', 'admin-add-node-dialog'],
      data: {
        treeData: treeData,
        additionalInvalidSlugs,
        formBuilderService: this.formBuilderService,
        addNodeFormBuilderService: this.addNodeFormBuilderService,
        path: addData.path,
      },
      maxWidth: '85vw',
      maxHeight: '90vh',
    }

    const dialogRef: MatDialogRef<QuestionnaireAddComponent> = this.dialogService.open(
      QuestionnaireAddComponent,
      dialogConfig
    )
    dialogRef.componentInstance.getObservable().subscribe((data: any) => {
      if (data.action === TreeNodeEditActions.CLOSE) {
        dialogRef.close()
      }

      if (data.action === TreeNodeEditActions.SAVE && data.formValid) {
        if (data.node.type === NODE_TYPE.BRANCH) {
          const addLeafdialogRef = this.matDialogService.open(SimpleDialogComponent, {
            role: 'dialog',
            data: {
              title: 'Add a Leaf Node',
              message: `This new Branch node requires that a new Leaf node be created so that it can be saved.`,
              actions: ['Cancel', 'Continue'],
            },
          })
          addLeafdialogRef.afterClosed().subscribe((dialogResponse: any) => {
            if (dialogResponse === 'Continue') {
              this.addAsChildOrReturnNode(data.node, parentNode)
              this.addNodeLocal(addData, data.node, rootParent)
            }
          })
        } else {
          this.addAsChildOrReturnNode(data.node, parentNode)
          const nodeToAdd = rootParent ? rootParent : data.node
          const newVersion = this.createNewVersion(addData, nodeToAdd)
          createNewCallback(newVersion)
        }

        dialogRef.close()
      }
    })
  }

  addAsChildOrReturnNode(node: any, parent?: any): any {
    if (parent) {
      parent[JSOCompareConstants.DEFAULT_CHILDREN_NODE_NAME] = [node]
    }
  }

  createNewVersion(addData: any, nodeToAdd: any): any {
    const { treeData, path } = addData
    const newVersion = JSON.parse(JSON.stringify(treeData))
    let pathAsArray = toPath(path)
    const activeItem = getFromObjectPath(treeData, pathAsArray)

    if (activeItem.type === NODE_TYPE.LEAF) {
      pathAsArray = pathAsArray.slice(0, pathAsArray.length - 1)
    } else {
      pathAsArray.push(JSOCompareConstants.DEFAULT_CHILDREN_NODE_NAME)
    }

    idfyObject(nodeToAdd, JSOCompareConstants.DEFAULT_NODE_ID, true)
    addObjectAtPath(newVersion, nodeToAdd, pathAsArray)

    return newVersion
  }
}
