// @ts-nocheck
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { size } from 'lodash'
@Component({
  selector: 'digital-platform-formly-field-select',
  template: `
  <div class="ruf-boxed-input-wrapper">
  <mat-form-field boxStyle floatLabel="always" style="width: 100%">
       <mat-select fisStyle  [formlyAttributes]="field" [formControl]="formControl" [placeholder]="field.props?.placeholder">
         <mat-option *ngFor="let option of this.field.props?.options" [value]="option.value">
           {{ option.label }}
         </mat-option>
       </mat-select>
       <formly-validation-message *ngIf="field.formControl?.touched && field.formControl?.invalid" [field]="field" style="position:relative;color:red;top:1em"></formly-validation-message>
     </mat-form-field>
 </div>
  `,
})

export class FormlyFieldSelectComponent extends FieldType {
  get value() {
    return this.getDeepProp(this.field.model, this.key.split('.'))
  }
  set value(value: any) {
    this.setDeepProp(this.field.model, this.key.split('.'), value)
  }

  getDeepProp(model: any, propPath: String[]) {
    if (size(propPath) === 1) {
      return model[propPath]
    }
    else {
      return this.getDeepProp(model[propPath[0]], propPath.slice(1))
    }
  }

  setDeepProp(model: any, propPath: String[], value) {
    if (size(propPath) === 1) {
      model[propPath] = value
    }
    else {
      this.setDeepProp(model[propPath[0]], propPath.slice(1), value)
    }
  }
}