import { Component } from '@angular/core'
import { FieldType, FieldTypeConfig } from '@ngx-formly/core'

@Component({
  selector: 'digital-platform-formly-field-base64-icon-file',
  templateUrl: './base64-icon-file.component.html',
  styleUrls: ['./base64-icon-file.component.scss'],
})
export class FormlyFieldBase64IconFileComponent extends FieldType<FieldTypeConfig> {
  onFileChange(event: any) {
    console.log(event.target)
    const file: File = event.target.files[0]
    this.form.get('icon')?.setValue(file)
  }
}
