import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { environment } from "../../environments/environment"
import { AdminUiAuthService } from "./admi-ui-auth.service"

@Injectable()
export class UploadService {
  fileApiUrl: string
  objectFileApiUrl: string

  constructor(
    private httpclient: HttpClient,
    private authService: AdminUiAuthService,
  ) {
    this.fileApiUrl = environment.apis.fileApiUrl
    this.objectFileApiUrl = environment.apis.uploadObjectFileUrl
  }

  // Use this for Migrate Data Import
  upload = (formData: any, type: string) => {
    return this.httpclient.post(
      `${this.fileApiUrl}/upload/${type}`,
      formData,
      { headers: this.authService.authHeaders }
    )
  }

  // Use this for Migrate Data Export
  download = (type: string) => {
    return this.httpclient.post(
      `${this.fileApiUrl}/published/${type}`,
      { headers: this.authService.authHeaders }
    )
  }

  importObjects = (type: string, data: any) => {
    return this.httpclient.post(
      `${this.objectFileApiUrl}/import/${type}`,
      data,
      { headers: this.authService.authHeaders }
    )
  }

  exportObjects = (type: string, data: { [key: string]: string[] }) => {
    return this.httpclient.post(
      `${this.objectFileApiUrl}/export/${type}`,
      data,
      { headers: this.authService.authHeaders }
    )
  }
}