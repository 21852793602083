import { size } from 'lodash'
import { BehaviorSubject, Subscription } from 'rxjs'
import { SubscribableService } from './subscribable-service'

export class VersionedService implements SubscribableService {
  versionChangeStack: any[] = []
  versionChangeStackSubject = new BehaviorSubject(this.versionChangeStack)
  versionChangeStack$ = this.versionChangeStackSubject.asObservable()

  subscribeToVersionChange(observer: (versionStack: any[]) => void): Subscription {
    return this.versionChangeStack$.subscribe(observer)
  }

  replaceVersion(versionedItem: any): void {
    this.versionChangeStack.splice(0, this.versionChangeStack.length, versionedItem)
    this.versionChangeStackSubject.next(this.versionChangeStack)
  }

  pushVersion(versionedItem: any): void {
    if (size(this.versionChangeStack) < 2) {
      this.versionChangeStack.push(versionedItem)
    } else {
      this.versionChangeStack[1] = versionedItem
    }
    this.versionChangeStackSubject.next(this.versionChangeStack)
  }

  resetVersion(): void {
    this.versionChangeStack.splice(1)
    this.versionChangeStackSubject.next(this.versionChangeStack)
  }

  getOriginalVersion(): any {
    if (size(this.versionChangeStack) <= 1) {
      return
    }

    return this.versionChangeStack[0]
  }

  getPrevioiusVersion(): any {
    if (size(this.versionChangeStack) <= 1) {
      return
    }

    return this.versionChangeStack[this.versionChangeStack.length - 2]
  }

  getCurrentVersion(): any {
    if (size(this.versionChangeStack) < 1) {
      return
    }

    return this.versionChangeStack[this.versionChangeStack.length - 1]
  }

  hasMoreVersionsOnStack(): boolean {
    return size(this.versionChangeStack) > 1
  }

  clearVersionStack(): void {
    this.versionChangeStack = []
    this.versionChangeStackSubject.next(this.versionChangeStack)
  }
}
