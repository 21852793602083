<div class="main">
  <div class="container">
    <ng-container *ngFor="let mConfig of getModules()">
      <div class="border">
        <div class="content-container">
          <div class="header">
            <mat-icon
              fisStyle
              fontSet="fisfont-solid"
              [class]="mConfig.getModuleNavigationMeta()?.moduleIcon || 'fis-icon-home' + ' ruf-solid'"
              style="font-size: 28px; color: #015b7e"
            ></mat-icon>
          </div>
          <div class="content">
            <div class="module-name" [matTooltip]="mConfig.getModuleTitle()">{{ mConfig.getModuleTitle() }}</div>
            <div class="card-action-bar">
              <div>
                <button mat-button fisStyle (click)="navigate(mConfig.getHomeUrl())" color="primary">Go to module</button>
                <button *ngIf="mConfig.getHistoryUrl()" mat-button fisStyle (click)="navigate(mConfig.getHistoryUrl())"
                  color="secondary">History</button>
                <button *ngIf="mConfig?.getUploadDocumentMeta()" mat-button fisStyle (click)="navigate('/migrate-data')" color="secondary">Migrate data</button>
              </div>
              <div></div>
            </div>
          </div>
          <div class="footer">
            <button #xsBtn mat-icon-button rufId color="primary" fisStyle (click)="toggleFavorites(mConfig.getModulePrefix())" matTooltip="Favorites">
              <mat-icon
                fisStyle
                fontSet="fisfont-solid"
                [class]="'footer-icon fis-icon-favorite' + (isFavorite(mConfig.getModulePrefix()) ? '-solid' : '') + ' ruf-solid'"
              ></mat-icon>
            </button>
            <mat-icon
              fisStyle
              fontSet="fisfont-solid"
              class="footer-icon fis-icon-information ruf-solid"
              [matTooltip]="MODULES_INFO[mConfig.getModulePrefix()]?.description"
            ></mat-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
