import { Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SimpleDialogData } from "./simple-dialog.data";

@Component({
  selector: 'digital-platform-simple-dialog',
  templateUrl: './simple-dialog.component.html'
})
export class SimpleDialogComponent {
  @Input()
  message: string

  constructor(private dialogRef: MatDialogRef<SimpleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimpleDialogData) { }

  close(closeAction: string) {
    this.dialogRef.close(closeAction)
  }

  isMultilineMessage(): boolean {
    return this.data.message instanceof Array
  }
}