import { Component, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { map, orderBy } from 'lodash'
import { Subscription } from 'rxjs'
import { AppService } from '../app.service'
import { MODULE_IN_MENU_ORDER_BY_MODULE_PREFIX_MAP } from '../common/model/app.constants'
import { ModuleConfigService } from '../common/services/module-config.service'
import { AdminUiAuthService } from '../service-api/admi-ui-auth.service'
import { getUngroupedModules } from './adminui-home.utils'
import MODULES_INFO from './modules-info.json'
@Component({
  selector: 'digital-platform-adminui-home-component',
  templateUrl: './adminui-home.component.html',
  styleUrls: ['./adminui-home.component.scss'],
})
export class AdminuiHomeComponent implements OnDestroy {
  moduleLoadSubscription: Subscription
  orderableLoadedModuleConfigServices: { moduleConfig: ModuleConfigService; orderNo: number }[] = []
  MODULES_INFO = MODULES_INFO

  constructor(private appService: AppService, private router: Router, private authService: AdminUiAuthService) {
    this.appService.subscribeToModuleLoading(this.onModuleLoadHandler.bind(this))
  }

  getModuleByName(modulePrefix: string): ModuleConfigService {
    return this.appService.getLoadedModuleConfigByModulePrefix(modulePrefix)
  }

  onModuleLoadHandler(moduleConfigService: ModuleConfigService): void {
    this.orderableLoadedModuleConfigServices.push({
      moduleConfig: moduleConfigService,
      orderNo: MODULE_IN_MENU_ORDER_BY_MODULE_PREFIX_MAP[moduleConfigService.getModulePrefix()],
    })
    this.orderableLoadedModuleConfigServices = [...orderBy(this.orderableLoadedModuleConfigServices, ['orderNo'])]
  }

  getOrderedLoadedModules(): ModuleConfigService[] {
    return map(this.orderableLoadedModuleConfigServices, (item: any) => item.moduleConfig)
  }

  getUngroupedModules(): string[] {
    return getUngroupedModules(this.getOrderedLoadedModules())
  }

  goToWikiPage(mConfig: ModuleConfigService): void {
    if (MODULES_INFO[mConfig.getModulePrefix()]?.wikiUrl) {
      window.open(MODULES_INFO[mConfig.getModulePrefix()]?.wikiUrl, '_blank')
    } else {
      console.log('Wiki url is not available.')
    }
  }

  getLoggedInUserFirstName(): string {
    return this.authService.getUserDetails().FirstName
  }

  navigate(url: string): void {
    this.router.navigate([url])
  }

  ngOnDestroy(): void {
    this.moduleLoadSubscription && this.moduleLoadSubscription.unsubscribe()
  }
}
