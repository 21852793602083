import { ComponentType } from '@angular/cdk/portal'
import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { ObservableDataHolder } from '../../../common/utils/observalbe-data-holder'

@Injectable({
  providedIn: 'root',
})
export class ObservableDialogService {
  constructor(private dialogService: MatDialog) {}

  open<T>(
    component: ComponentType<T & ObservableDataHolder>,
    options: MatDialogConfig
  ): MatDialogRef<T & ObservableDataHolder> {
    const dialogRef = this.dialogService.open(component, options)

    dialogRef.componentInstance.data = options.data

    return dialogRef
  }
}
