import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core'
import { FormlyMaterialModule } from '@ngx-formly/material'
import { RufMaterialModule } from '@ruf/material'
import { RufRichTextEditorModule } from '@ruf/rich-text-editor'
import { RufDropdownPanelModule, RufLabeledIconModule, RufTriggerModule } from '@ruf/shell'
import { GridWrapper } from '../field-wrappers/grid-wrapper'
import { FormlyFieldBase64IconFileComponent } from './base64-icon-file/base64-icon-file.component'
import { FormlyFieldCheckComponent } from './checkbox-template'
import { FormlyFieldEmptyComponent } from './empty-template'
import { FormlyFieldFileInputComponent } from './file-input/file-input.component'
import { FormlyRichTextEditorComponent } from './formly-rich-text-editor/formly-rich-text-editor-template'
import { FormlyFieldInputComponent } from './input-template'
import { FormlyFieldInputArrayComponent } from './input-template-array/input-template-array'
import { FormlyFieldRemovableArrayComponent } from './removable-formly-array-component/removable-formly-array-component'
import { FormlyFieldSelectComponent } from './select-template'
import { FormlyFieldTextAreaComponent } from './textarea-template/textarea-template.component'
import { RufFormlyFieldToggleComponent } from './toggle/ruf-toggle-template'
import { FormlyFieldToggleComponent } from './toggle/toggle-template'
import { TooltipWithToggleComponent } from './toggle/toggle-with-tooltip-template'

@NgModule({
  declarations: [
    FormlyFieldCheckComponent,
    TooltipWithToggleComponent,
    FormlyFieldSelectComponent,
    FormlyFieldInputComponent,
    FormlyFieldToggleComponent,
    FormlyFieldTextAreaComponent,
    FormlyFieldInputArrayComponent,
    GridWrapper,
    FormlyFieldEmptyComponent,
    FormlyFieldRemovableArrayComponent,
    FormlyFieldFileInputComponent,
    FormlyRichTextEditorComponent,
    FormlyFieldToggleComponent,
    RufFormlyFieldToggleComponent,
    FormlyFieldBase64IconFileComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    RufMaterialModule,
    RufDropdownPanelModule,
    RufTriggerModule,
    MatIconModule,
    RufLabeledIconModule,
    MatButtonModule,
    RufRichTextEditorModule,
    CKEditorModule,
    FormlyModule.forRoot({
      extras: { lazyRender: true },
      types: [
        {
          name: 'ruf-input',
          component: FormlyFieldInputComponent,
        },
        {
          name: 'ruf-checkbox',
          component: FormlyFieldCheckComponent,
        },
        {
          name: 'ruf-select',
          component: FormlyFieldSelectComponent,
        },
        {
          name: 'ruf-quest-toggle',
          component: RufFormlyFieldToggleComponent,
        },
        {
          name: 'ruf-toggle',
          component: FormlyFieldToggleComponent,
        },
        {
          name: 'ruf-textarea',
          component: FormlyFieldTextAreaComponent,
        },
        {
          name: 'ruf-toggle-tooltip',
          component: TooltipWithToggleComponent,
        },
        { name: 'ruf-input-array', component: FormlyFieldInputArrayComponent },
        { name: 'empty', component: FormlyFieldEmptyComponent },
        { name: 'removable-array', component: FormlyFieldRemovableArrayComponent },
        { name: 'ruf-rich-text-editor', component: FormlyRichTextEditorComponent },
        { name: 'file-input', component: FormlyFieldFileInputComponent },
        { name: 'base64-icon-file', component: FormlyFieldBase64IconFileComponent },
      ],
      wrappers: [{ name: 'grid-wrapper', component: GridWrapper }],
      validationMessages: [
        {
          name: 'required',
          message: 'This field is required',
        },
        {
          name: 'minLength',
          message: (error, field: FormlyFieldConfig) => `Please enter at least ${field.props?.minLength} characters.`,
        },
        {
          name: 'maxLength',
          message: (error, field: FormlyFieldConfig) => `Please enter at most ${field.props?.minLength} characters.`,
        },
        {
          name: 'pattern',
          message: 'Please check the format of the input.'
        },
        {
          name: 'invalidFileType',
          message: (error, field: FormlyFieldConfig) => `Please note that only ${field.props?.allowedFileTypes || 'jpeg, jpg, png, svg'} files are allowed.`,
        },
        {
          name: 'fileSizeExceeded',
          message: 'The file size can not exceed 3MB'
        },
        {
          name: 'notUnique',
          message: 'This field must be unique',
        },
        {
          name: 'email',
          message: 'Please enter a valid email address',
        },
      ]
    }),
    FormlyMaterialModule,
  ],
})
export class FormlyTemplatesModule {}
