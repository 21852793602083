import { filter, map } from "lodash"
import { ModuleConfigService } from "../common/services/module-config.service"
import { flattenByKey } from "../common/utils/collection.utils"

export const MODULE_GROUP = {
  questionnaired: {
    groupName: 'Questionnaires',
    modules: ['gsc', 'itdp', 'gbt']
  }
}

export const flattenGroupedModules = () => {
  return flattenByKey(Object.values(MODULE_GROUP), 'modules', true)
}

export const getUngroupedModules = (loadedModuleConfigServices: ModuleConfigService[]): string[] => {
  const groupedModules: string[] = flattenByKey(Object.values(MODULE_GROUP), 'modules', true)
  const ungroupedModules = filter(loadedModuleConfigServices, (mConfigSvc: ModuleConfigService) => !groupedModules.includes(mConfigSvc.getModulePrefix()))
  return map(ungroupedModules, (unGroupedModule: ModuleConfigService) => unGroupedModule.getModulePrefix())
}