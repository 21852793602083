<div *ngIf="field.props?.label" style="text-align: center; font-weight: 700;">
  <span>{{ field.props?.label }}</span>
</div>
<div style="width: 100%; display: grid" [style]="field.props?.gridConfig">
  <ng-container *ngFor="let f of field.fieldGroup">
    <ng-container *ngIf="!f.fieldGroup && f.type">
      <div style="display: flex; align-items: center">
        <mat-label style="font-weight: 700;">{{ f.props?.label }}</mat-label>
        <span *ngIf="f.props.required" class="form-required-input">*</span>
      </div>
      <div>
        <formly-field [field]="f"></formly-field>
      </div>
    </ng-container>

    <!-- HANDING ARRAYS IN JSON START -->
    <ng-container *ngIf="f.fieldGroup && f.fieldGroup[0].fieldGroup">
      <ng-container *ngFor="let fg of f.fieldGroup; let i = index">
        <ng-container *ngFor="let f1 of fg.fieldGroup">
          <ng-container *ngIf="f1.type">
            <div style="display: flex; align-items: center">
              <mat-label style="font-weight: 700;">{{ f1.props?.label }}</mat-label>
              <span *ngIf="f1.props.required" class="form-required-input">*</span>
            </div>
            <div>
              <formly-field [field]="f1"></formly-field>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <!-- HANDING ARRAYS IN JSON END    -->
  </ng-container>
</div>
<hr class="divider" />