import { includes, size } from "lodash";
import { nanoid } from "nanoid";
import { JSOCompareConstants } from "./JsonCompareConstants";

export const sortObjectProperties = (obj: any) => {
  if (obj instanceof Array) {
    for (let i = 0; i < obj.length; i++) {
      obj[i] = sortObjectProperties(obj[i])
    }
    return obj
  } else if (obj === null || typeof obj === 'undefined' || typeof obj != "object") {
    return obj;
  }

  const keys = Object.keys(obj);
  keys.sort();
  const newObject: { [key: string]: any } = {};
  for (let i = 0; i < keys.length; i++) {
    newObject[keys[i]] = sortObjectProperties(obj[keys[i]])
  }
  return newObject;
}

export const idfyObject = (obj: any, idKey: string = JSOCompareConstants.DEFAULT_NODE_ID, refill: boolean = false, level: number = 0) => {
  if (obj instanceof Array) {
    for (let i = 0; i < obj.length; i++) {
      obj[i] = idfyObject(obj[i], idKey, refill, level++)
    }
    level--
    return obj
  } else if (obj === null || typeof obj === 'undefined' || typeof obj != "object") {
    level--
    return obj;
  }

  if (!refill && obj[idKey]) {
    throw new Error(`The object already contains ${idKey}`)
  } else if (!obj[idKey]) {
    obj[idKey] = nanoid()
  }

  const keys = Object.keys(obj)
  for (let i = 0; i < keys.length; i++) {
    if (typeof obj[keys[i]] === 'object') {
      obj[keys[i]] = idfyObject(obj[keys[i]], idKey, refill, level++)
    }
  }

  level--
  return obj
}

export const getCurrentPath = (obj: any, nodeName: any, nodeValue: string | number, currentPath?: any): string => {
  const result = getCurrentPathRecursive(obj, nodeName, nodeValue, currentPath)
  return result?.path || ''
}

const getCurrentPathRecursive = (obj: any, nodeName: any, nodeValue: string | number, currentPath?: any): { path: string, found?: boolean } => {
  if (!obj && !currentPath && obj instanceof Array) {
    return { path: '0', found: true }
  }

  let path = currentPath || "";

  if (!obj) {
    return { path, found: false }
  }

  if (obj[nodeName] === nodeValue) {
    return { path, found: true }
  }

  if (obj instanceof Array) {
    for (let i = 0; i < obj.length; i++) {
      const r = getCurrentPathRecursive(obj[i], nodeName, nodeValue, `${path}[${i}]`)
      if (r?.found) {
        return r
      }
    }
  }

  const keys: string[] = Object.keys(obj)
  for (let i = 0; i < keys.length; i++) {
    const subObj = obj[keys[i]]
    const key = keys[i]
    if (subObj && typeof subObj === 'object') {
      const r = getCurrentPathRecursive(subObj, nodeName, nodeValue, `${path}.${key}`)
      if (r?.found) {
        return r
      }
    }
  }

  return { path }
}

export const updateObjectAtPath = (objectToUpdate: any, newValue: any, objPath: string[], attributesToSkip: string[] = []) => {
  let path = JSON.parse(JSON.stringify(objPath))
  let object = objectToUpdate

  while (path.length > 0) {
    // @ts-ignore
    object = object[path.shift()];
  }

  // @ts-ignore
  for (const objKey in newValue) {
    // iteration over new object will ensure that new attributes will be added
    if (!includes(attributesToSkip, objKey)) {
      object[objKey] = newValue[objKey];
    }
    /*
     TODO: here you should ensure that deleted attributes are removed. 
     We don't need it for now so we don't want to slow down the execution with the feature
     we don't want to use.
     */
  }
}

export const addObjectAtPath = (objectToUpdate: any, newValue: any, objPath: string[], idKey = JSOCompareConstants.DEFAULT_NODE_ID) => {
  let path = JSON.parse(JSON.stringify(objPath))
  let object = objectToUpdate

  while (path.length > 1) {
    // @ts-ignore
    object = object[path.shift()];
  }

  // generate new key only if it does not exist. It will exist in case of node move.
  newValue[idKey] = newValue[idKey] ? newValue[idKey] : nanoid()

  const attr = path[0]
  if (object[attr] instanceof Array) {
    object[attr].push(newValue)
  } else {
    if (object instanceof Array) {
      // this means element move
      object.splice(attr, 0, newValue)
    } else {
      object[attr] = newValue
    }
  }
}

export const generateNodeId = () => nanoid()

export const deleteObjectAtPath = (objectToUpdate: any, objPath: string[]) => {
  let path = JSON.parse(JSON.stringify(objPath))
  let object = objectToUpdate

  if (size(path) === 0) {
    return
  }

  while (path.length > 1) {
    // @ts-ignore
    object = object[path.shift()];
  }

  if (object instanceof Array) {
    object.splice(parseInt(path[0]), 1);
  } else {
    delete object[parseInt(path[0])]
  }
}

// this function is used to restore deleted items to object
export const nestDeletedObject = (objectToUpdate: any, objPath: string[], value: any) => {
  let path = JSON.parse(JSON.stringify(objPath))
  let object = objectToUpdate

  if (size(path) === 0) {
    return
  }

  while (path.length > 1) {
    // @ts-ignore
    object = object[path.shift()];
  }

  if (object instanceof Array && path[0]) {
    object.splice(parseInt(path[0]), 0, value);
  } else {
    object[path[0]] = value
  }
}

export const getFromObjectPath = (objectToUpdate: any, objPath: string[]) => {
  let path = JSON.parse(JSON.stringify(objPath))
  let object = objectToUpdate

  if (!object) {
    return
  }

  while (path.length > 0) {
    // @ts-ignore
    object = object[path.shift()];
    if (!object) {
      return object
    }
  }

  return object
}

export const deleteAttribute = (obj: any, attr: string) => {
  if (obj instanceof Array) {
    for (let i = 0; i < obj.length; i++) {
      obj[i] = deleteAttribute(obj[i], attr)
    }
    return obj
  } else if (obj === null || typeof obj === 'undefined' || typeof obj != "object") {
    return obj;
  }

  delete obj[attr]

  const keys = Object.keys(obj)
  for (let i = 0; i < keys.length; i++) {
    if (typeof obj[keys[i]] === 'object') {
      obj[keys[i]] = deleteAttribute(obj[keys[i]], attr)
    }
  }

  return obj
}