import { Injectable } from '@angular/core';
import { UserRoles } from '@digital-platform/model';
import { AuthService } from '@ruf/idp-auth';
import { BehaviorSubject, forkJoin, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminUiAuthService {
  private accessToken: string
  private loggedinUser: any
  private loadUserRole = new BehaviorSubject(null)
  private loadUserRole$ = this.loadUserRole.asObservable().pipe(take(1))
  private adminRoles: string[]

  constructor(private idpService: AuthService) {
    this.authorize()
  }

  getLoadUserRoleObs(): Observable<any> {
    return this.loadUserRole$
  }

  getAdminRoles(): string[] {
    return this.adminRoles
  }

  authorize(): void {
    forkJoin([this.idpService.idToken$.pipe(take(1)), this.idpService.claims$.pipe(take(1))]).subscribe({
      next: ([token, user]) => {
        this.accessToken = token
        this.loggedinUser = JSON.parse(user)
        this.adminRoles = (this.loggedinUser?.GSCAdminUIAuthList?.split(',') || [])
          .concat(UserRoles.GSC_ADMINUI_MODULE_GBT_USER)
          .concat(UserRoles.GSC_ADMINUI_MODULE_RESB_USER)
          .concat(UserRoles.GSC_ADMINUI_MODULE_VERSIONLOG_USER)
        this.loadUserRole.next(this.getAdminRoles())
      },
    })
  }

  get authHeaders() {
    return {
      Accept: 'application/json',
      Authorization: `Bearer ${this.accessToken}`,
    }
  }

  getLoggedInUser() {
    return this.loggedinUser
  }

  getUserDetails() {
    return this.loggedinUser
  }

  isAdminUser() {
    const roles = this.getAdminRoles()
    return roles.includes(UserRoles.GSC_ADMINUI_USER)
  }

  isAdminApprover() {
    const roles = this.getAdminRoles()
    return roles.includes(UserRoles.GSC_ADMINUI_APPROVER)
  }

  isAdminPublisher() {
    const roles = this.getAdminRoles()
    return roles.includes(UserRoles.GSC_ADMINUI_PUBLISHER)
  }

  hasWebhookUser() {
    const roles = this.getAdminRoles();
    return roles?.includes(UserRoles.GSC_ADMINUI_WEBHOOK_CONFIG);
  }

  isWebhookGeneralUser() {
    const roles = this.getAdminRoles();
    return roles.includes(UserRoles.GSC_ADMINUI_GENERAL_CONFIG)
  }

  isOnlyWebhookUser() {
    const roles = this.getAdminRoles();
    return roles?.includes(UserRoles.GSC_ADMINUI_WEBHOOK_CONFIG) && !roles?.includes(UserRoles.GSC_ADMINUI_GENERAL_CONFIG);
  }
}
