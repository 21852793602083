<div fxLayout="column" class="admin-ui-root" [dir]="'ltr'" rufId="demo_layout">
  <ruf-banner class="admin-ui-banner">
    <button fisStyle mat-icon-button class="gsc-admin-mainbtn mat-focus-indicator mat-icon-button mat-button-base fis-icon-button-md" (click)="start.toggle()">
      <mat-icon fontset="fisfont" fonticon="fis-icon-menu" ruficonstyle="md" class="mat-icon notranslate fisfont fis-icon-menu fis-icon-md mat-icon-no-color"></mat-icon>
    </button>
    <digital-platform-select-module-dropdown [selectedPath]="selectedPath"></digital-platform-select-module-dropdown>
    <div class="ruf-banner-filler"></div>
    <ruf-menubar class="admin-right-menubar">
      <a rufId fisStyle rufMenubarItem class="gsc-admin-link">
        <div class="admin-ui-profile">
          <div class="admin-ui-circle">
            <div class="admin-user-initials">{{ userInitials || '' }}</div>
          </div>
        </div>
      </a>
    </ruf-menubar>
  </ruf-banner>
  <mat-sidenav-container [hasBackdrop]="true" class="admin-ui-sidenav-container" fisStyle fxFlex autosize hasBackdrop="false">
    <mat-sidenav #start="" [autoFocus]="false" [mode]="mode" [fixedTopGap]="fixedTop" [fixedBottomGap]="fixedBottom" fisStyle>
      <digital-platform-side-menu [items]="items" (sideMenuSelected)="sideMenuSelected($event); start.close()" [selectedPath]="selectedPath"></digital-platform-side-menu>
    </mat-sidenav>
    <mat-sidenav-content class="content-background" autoFocus="false" mode="over" position="end">
      <div class="content-container admin-ui-home-layout">
        <digital-platform-statusbar
          [color]="'info'"
          [border]="false"
          [closeable]="false"
          statusBarTitle="The back button in all browsers is inoperable at this time and will be available in a future product enhancement"
          [iconName]="'information-solid'"
        ></digital-platform-statusbar>
        <ruf-app-canvas fxFlex class="admin-ui-app-canvas">
          <router-outlet></router-outlet>
        </ruf-app-canvas>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <ruf-footer> &copy; {{ showCurrentYear }} FIS v{{ appVersion }} </ruf-footer>
</div>
