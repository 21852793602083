import { create, DiffPatcher } from 'jsondiffpatch';
import config from './jsondiffpatch.config';

export class JsonDiffPatch {
  static instance: DiffPatcher;

  // eslint-disable-next-line
  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = create(config);
    }
    return this.instance;
  }
}