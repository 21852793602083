import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'digital-platform-formly-field-base64-icon-file',
  templateUrl: './base64-icon-file.component.html',
  styleUrls: ['./base64-icon-file.component.scss'],
})
export class FormlyFieldBase64IconFileComponent extends FieldType<FieldTypeConfig> {
  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    // List of allowed file extensions
    const allowedFileExtensions = this.props.allowedFileTypes || ['svg', 'png', 'jpeg', 'jpg'];
    // Maximum file size in bytes (3MB)
    const maxSize = 3 * 1024 * 1024;

    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension && allowedFileExtensions.includes(fileExtension)) {
        if (file.size <= maxSize) {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result as string;
            this.updateFormControl(base64String);
          };
          reader.readAsDataURL(file);
        } else {
          console.error('File size exceeds 3MB. Please upload a smaller file.');
          if (this.formControl) {
            this.resetControl();
            // The error is configured in the module.
            this.formControl.setErrors({ fileSizeExceeded: true });
          }
        }
      } else {
        console.error('Invalid file type. Please upload a valid file.');
        if (this.formControl) {
          this.resetControl();
          // The error is configured in the module.
          this.formControl.setErrors({ invalidFileType: true });
        }
      }
    }
  }

  updateFormControl(base64String: string) {
    if (this.formControl) {
      this.formControl.setValue(base64String);
    } else {
      console.error('Form control "icon" not found.');
    }
  }

  resetControl() {
    this.formControl.setValue(null);
  }
}
