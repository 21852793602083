import { Delta } from "jsondiffpatch";
import { AbstractJsonDiffPatch } from "./abstract-jsondiffpatc";

export class VersionDeltaPatcher extends AbstractJsonDiffPatch {
  patch(left: any, delta: Delta): any {
    return this.getJsondiffInstance().patch(left, delta)
  }

  unpatch(right: any, delta: Delta): any {
    return this.getJsondiffInstance().unpatch(right, delta)
  }
}