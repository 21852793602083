import { find } from 'lodash'

const findField = (fieldName: string, object: any) => {
  let field = find(object, { key: fieldName })
  if (field) {
    return field
  }

  for (const item of object) {
    if (item && Object.prototype.hasOwnProperty.call(item, 'fieldGroup')) {
      field = findField(fieldName, item.fieldGroup)
      if (field) {
        return field
      }
    }
  }
}

export const updateField = (fieldName: string, object: any, props: any) => {
  const field = findField(fieldName, object)
  if (field) {
    for (const prop of Object.keys(props)) {
      field[prop] = isPrimitive(props[prop]) ? props[prop] : { ...field[prop], ...props[prop] }
    }
  }
}

export const isPrimitive = (value: any): boolean => {
  return value !== Object(value)
}
