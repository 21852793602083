export const SAVE_NODES = 'The node changes that you submitted were successfully added';
export const APPROVE_GUIDED_JOURNEY = 'The selected Guided Journey has been approved';
export const PUBLISH_GUIDED_JOURNEY = 'The approved Guided Journey has been published';
export const PUBLISH_LATER = 'The approved Guided Journey has been scheduled to publish';
export const DELETE_NODES = 'Selected node(s) will be removed after you Preview and Submit';
export const SAVE_TICKET = 'The Ticket Flow that you created has been submitted and added successfully';
export const GUIDED_JOURNEY_BREADCRUMB = 'guided-journey';
export const TICKET_ENRICHMENT_BREADCRUMB = 'ticket-enrichment';
export const MIGRATE_DATA_BREADCRUMB = 'migrate-data'
export const SAVE_IMPORT_DATA = 'The data type that you selected has been successfully imported'
export const RADIO_BUTTON_EXPORT_VALUE = 'Export Data';
export const CREATEDBY_SEEDING_SCRIPT = 'seeding script';

export const ADD_NEW_NODE_LABEL = 'Add New Node';
export const DELETE_NODE_LABEL = 'Delete Node(s)';