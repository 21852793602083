import { Component, Input } from '@angular/core';

@Component({
  selector: 'digital-platform-statusbar',
  templateUrl: './statusbar.component.html',
  styleUrls: ['./statusbar.component.scss']
})
export class StatusbarComponent {
  @Input() color: string;
  @Input() border: string;
  @Input() closeable: string;
  @Input() statusBarTitle: string;
  @Input() iconName: string;
}
