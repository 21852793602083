<div class="formly-form-file-input-component-container">
  <input hidden type="file" #fileUploader />
  <mat-label class="label">
    {{ field.props.label }}
  </mat-label>
  <button type="button" class="file-upload-button" aria-label="choose file" mat-button (click)="fileUploader.click()">
    <ruf-labeled-icon fontSet="fisfont" iconClass="fis-icon-sm ruf-foreground-primary" fontIcon="fis-icon-add">
      Attach File
    </ruf-labeled-icon>
  </button>
</div>
