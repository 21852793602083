import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'digital-platform-formly-rich-text-editor',
  templateUrl: './formly-rich-text-editor-template.html',
  styleUrls: ['./formly-rich-text-editor-template.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormlyRichTextEditorComponent extends FieldType<FieldTypeConfig> {
  public editor = ClassicEditor;
  public config = {
    placeholder: 'Type the content here!'
  }
  constructor() {
    super()
    this.editor.defaultConfig = {
      toolbar: {
        items: ['bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
      },
      language: 'en',
      image: {
        toolbar: [
          'imageStyle:full',
          'imageStyle:side',
          '|',
          'imageTextAlternative'
        ]
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
      },
    };
  }

  onReady() {
    if (!this.field.formControl.value) {
      this.field.formControl.setValue('');
    }
  }

}