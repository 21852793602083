<div *ngIf="field.props?.label" class="fx-row bold justify-content-center">
  <span>{{ field.props.label }}</span>
</div>
<div *ngFor="let f of field.fieldGroup; let i = index" class="need-help-container" [style]="field.props.gridConfig">
  <ng-container *ngFor="let f1 of f.fieldGroup; let i1 = index">
    <div class="fx-row">
      <label class="bold">{{f1.props?.label}}</label>
      <span *ngIf="f1.props?.required" class="form-required-input">*</span>
    </div>
    <div class="fx-row">
      <formly-field class="col" [field]="f1" style="width: 100%"></formly-field>
    </div>
  </ng-container>
  <div *ngIf="!field.props.hideDeleteButton" class="fx-row">
    <button mat-icon-button fisStyle (click)="remove(i)">
      <mat-icon fisStyle fontSet="fisfont" [fontIcon]="'fis-icon-trash'"></mat-icon>
    </button>
  </div>
</div>

<button type="button" color="primary" fisStyle mat-stroked-button [disabled]="isAddDisabled()"
  (click)="add()">Add</button>