export const ModuleNames = {
  GSC: 'gsc',
  ITDP: 'itdp',
  GBT: 'gbt',
  RESB: 'resb',
  CTA: 'cta',
  KEYWORD_DETECTION: 'keyword-detection',
  VERSIONLOG: 'versionlog',
  CALL_SUPPORT: 'call-support',
  QUICK_LINKS: 'quick-links',
  SDM: 'sdm',
  RECOMMENDATIONS: 'recommendations',
  TOUR_GOUDE: 'tour-guide',
  MEGAMENU: 'mega-menu',
  WEBHOOK: 'webhook',
}
