<ruf-dialog-panel [separator]="true" class="admin-ui-error-dialog" rufId="dialog_without_header"
  [closeIconTitle]="'This is custom close icon title'">
  <ruf-dialog-header rufId="simple_dialog_header" class="dialog-header" #header>
    <span *ngIf="generalError || importError">{{data.action}} Request Cannot Be Completed</span>
    <span *ngIf="accessDenied">Access Denied</span>
  </ruf-dialog-header>
  <ruf-dialog-content class="dialog-body" rufId="simple_dialog_content" rufScrollbar>
    <div class="dialog-content" *ngIf="generalError">
      The action you are requesting, <span>{{data.action}}</span>, cannot be completed at this time.
      Please Try again later.
    </div>
    <div class="dialog-content" *ngIf="accessDenied">
      You don’t have access to this feature. To gain access, contact your Administrator by sending an email to gsc-adminui-dev@fisglobal.com or submit a ticket.
    </div>
    <div *ngIf="importError">You can import only published version.</div>
    <mat-icon fisIcon="error-solid" rufIconStyle="xl" color="error" class="error-icon error-icon-w"></mat-icon>
  </ruf-dialog-content>
</ruf-dialog-panel>