import { Component } from '@angular/core';
import { FieldArrayType, FieldArrayTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'digital-platform-removable-array',
  templateUrl: './removable-formly-array-component.html',
  styleUrls: ['removable-formly-array-component.scss']
})
export class FormlyFieldRemovableArrayComponent extends FieldArrayType<FieldArrayTypeConfig> {
  isAddDisabled(): boolean {
    if (!this.field.formControl.valid) {
      return true
    }

    if (Number.isInteger(this.field.props?.rows)) {
      // @ts-ignore
      return !((this.field.fieldGroup?.length || 0) < this.field.props.rows)
    }

    return false
  }
}