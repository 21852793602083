// @ts-nocheck
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'digital-platform-formly-field-input',
  template: `
 <section class="example-section">
      <mat-checkbox fisStyle [color]="color" [style]="to.style" [formControl]="formControl" (change)="updateFormControlValue()" [formlyAttributes]="field" ></mat-checkbox>
      </section>
 `,
})
export class FormlyFieldCheckComponent extends FieldType {
  checked: any;
  color = "primary";

  updateFormControlValue() {
    const val = !!this.formControl.value ? true : false;
    this.formControl.setValue(val);
  }
}