<div fxFlex fxLayout="row" class="select-module-dropdown">
    <button rufId mat-button class="banner-button" (click)="landingPage.toggle()">
        <ruf-banner-brand [title]="title" tabindex="0">
        </ruf-banner-brand>
    </button>
    <mat-form-field>
        <mat-select panelClass="select-module-dropdown-panel" fisStyle #landingPage matNativeControl>
            <ng-container *ngFor="let option of switchPortalOptions">
                <mat-option [value]="option.label" (onSelectionChange)="routeToLandingPage(option.path, $event)">
                    <ruf-labeled-icon iconClass="icon-color-blue" fontIcon="{{option.icon}}">{{option.label}}</ruf-labeled-icon>
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>