<div class="admin-ui-loading-indicator" *ngIf="!dataLoaded">
  <mat-progress-spinner mode="indeterminate" [strokeWidth]="1" [diameter]="30" color="primary"
    fisStyle></mat-progress-spinner>
</div>
<digital-platform-ruf-stable-breadcrumb *ngIf="dataLoaded" [breadCrumbs]="[
  { title: 'Home', slug: '' },
  { title: 'Advance Admin', slug: '' },
  { title: 'Migrate Data', slug: ''}]">
</digital-platform-ruf-stable-breadcrumb>
<div class="admin-ui-migrate-data" *ngIf="dataLoaded">
  <div class="migrate-data" tabindex="0">Migrate Data</div>
  <div class="migrate-data-fields" [formGroup]="formData">
    <div class="type-section">
      <label class="field-label">Select Data Type<span class="form-required-input">*</span></label>
      <mat-form-field boxStyle floatLabel="always">
        <mat-select fisStyle placeholder="Select Type" formControlName="type"
          (selectionChange)="typeSelectionChange($event.value)" required>
          <mat-option *ngFor="let type of dataType" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
        <div *ngIf="selectDataType?.touched && selectDataType.invalid">
          <div *ngIf="selectDataType.errors?.required" class="migrate-form-validation-errors">
            Select type is required.
          </div>
        </div>
      </mat-form-field>
    </div>
    <div class="reset-btn">
      <button rufId mat-button="" fisStyle [disabled]="selectDataType.value ? false : true"
        (click)="formData.reset();">Reset</button>
    </div>
  </div>

  <div class="migrate-data-dynamic-segment">
    <ruf-dynamic-menubar rufId="dynamic_menubar_icon_label_items" [items]="dynamicSegmentItems"
      [selectedPath]="currentSelectedPath" (selectChange)="currentSelectedPath = $event.path">
    </ruf-dynamic-menubar>
  </div>

  <div [ngSwitch]="currentSelectedPath">
    <ng-container *ngSwitchCase="'file'" [ngTemplateOutlet]="fileExchangeTemplate"></ng-container>
    <ng-container *ngSwitchCase="'gsc'" [ngTemplateOutlet]="gscExchangeTemplate"></ng-container>
  </div>

  <ng-template #gscExchangeTemplate>
    <button [disabled]="!selectDataType.value || !isUserPublisher() || isNotSupportedByType(MigrationTypes.GSC)"
      class="submit-btn block" mat-stroked-button fisStyle color="primary" (click)="importFromGsc()">
      Import
    </button>
    <p class="adminui info">
      <b>Import.&nbsp;</b>This action should be used to sync data with GSC System. Please note that imported data will
      be accessible from
      history page under <b>Imported</b> section. If the currently published version is equal to the version available
      in GSC the import won't occur.
    </p>
    <div *ngIf="showExportToGsc && isUserPublisher()">
      <button [disabled]="!selectDataType.value || isNotSupportedByType(MigrationTypes.GSC)" class="submit-btn block"
        mat-stroked-button fisStyle color="primary" (click)="exportToGsc()">
        Export
      </button>
      <p class="adminui info">
        <b>Export.&nbsp;</b>This action should be used to sync data with GSC System. It will export currently published
        version of the selected module. <b>GSC data will be overwritten.</b>
      </p>
    </div>
  </ng-template>

  <ng-template #fileExchangeTemplate>
    <mat-radio-group class="import-export" [formControl]="radioBtnControl" (change)="changeRadioOptions()">
      <div>
        <mat-radio-button (click)="selectOption('import')" (keydown.enter)="handleKeyDown('import')"
          [checked]="selectedImport" fisStyle [value]="'Import Data'" class="import-radio-btn">Import Data
        </mat-radio-button>
      </div>
      <div *ngIf="selected === 'import'" class="file-import-container">
        <form #form="ngForm" class="upload-form">
          <ruf-file-upload #rufUploadInst name="file1" [disabled]="!selectDataType.value" (upload)="saveFile($event)"
            [(ngModel)]="file1" [accept]="'.JSON'" [rufFileType]="fileExtn" [label]="'Import Data'" position="bottom"
            #jsonFile="ngModel" (click)="resetFile()">
            <ruf-file-template class="demo-file-template" (click)="$event.stopPropagation()">
              <div *ngIf="file1" ngClass.xs="mobile" fxLayout="column wrap" fxLayout.xs="column wrap">
                <digital-platform-progress-tile class="admin-ui-progress-tile" (click)="$event.stopPropagation()">
                  <div digitalPlatformIcon>
                    <div class="icons">
                      <div class="file-uploaded">
                        <mat-icon class="file" rufIconStyle="xl" fisStyle [fisIcon]="'file'" alt="file">
                        </mat-icon>
                        <button class="delete" (click)="handleDeleteFile(file1)" type="button" fisStyle mat-icon-button>
                          <mat-icon rufIconStyle="lg" fisStyle fisIcon="trash" alt="delete"></mat-icon>
                        </button>
                        <button class="abort" (click)="handleAbortUpload(file1)" type="button" fisStyle mat-icon-button>
                          <mat-icon rufIconStyle="lg" fisStyle fisIcon="close" alt="cancel"></mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div digitalPlatformUploadTitle class="file-name"><b>{{file1?.name}}</b></div>
                  <div digitalPlatformUploadProgress>
                    <ng-container>{{file1?.size | rufFileSize : 0}}</ng-container>
                  </div>
                </digital-platform-progress-tile>
                <div class="admin-ui-file-error" *ngIf="file1?.extension !== 'json'"> Invalid File Type </div>
                <div class="admin-ui-file-error" *ngIf="file1?.fileLimit"> Invalid File Size </div>
              </div>
            </ruf-file-template>
          </ruf-file-upload>
        </form>

        <p class="adminui import-info" *ngIf="FileActions.IMPORT === selected">
          This action should be used to import previously exported documents. Please note that import has to be done by
          module.
        </p>

        <button [disabled]="!(file1) || file1?.extension !== 'json' || file1?.fileLimit" class="submit-btn"
          mat-stroked-button fisStyle color="primary" (click)="saveFileApiCall()">
          Submit
        </button>
      </div>
      <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="10px">
        <mat-radio-button (click)="selectOption('export')" (keydown.enter)="handleKeyDown('export')"
          [checked]="selectedExport" [value]="'Export Data'" fisStyle class="export-radio-btn">Export Data
        </mat-radio-button>
      </div>
    </mat-radio-group>
    <div *ngIf="selected === 'export'" class="admin-ui-migrate-export">
      <button [disabled]="!(selectDataType.value)" mat-raised-button color="primary" fisStyle
        class="admin-ui-download-button" (click)="downloadFile()">
        <ruf-labeled-icon [iconPosition]="'before'" iconClass="fis-icon-md" [fontSet]="'fisfont'"
          fontIcon="fis-icon-download">Export Data</ruf-labeled-icon>
      </button>
    </div>
  </ng-template>
</div>