import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserJourneyStatus } from '@digital-platform/model';
import { RufDynamicMenubarItem } from '@ruf/shell';
import { FileSaverService } from 'ngx-filesaver';
import { lastValueFrom, Subscription } from 'rxjs';
import { AppService } from '../../app.service';
import { ModuleConfigService } from '../../common/services/module-config.service';
import { SpinnerService } from '../../common/spinner-overlay/spinner-overlay.service';
import { AdminUiAuthService } from '../../service-api/admi-ui-auth.service';
import { UploadService } from '../../service-api/upload.service';
import { UtilityService } from '../../utility.service';
import { SimpleDialogComponent } from '../../common/components/dialogs/simple-dialog/simple-dialog.component';
import { RADIO_BUTTON_EXPORT_VALUE, SAVE_IMPORT_DATA } from '../messages.constants';
import { MODULE_IN_MENU_ORDER_BY_MODULE_PREFIX_MAP } from '../../common/model/app.constants';
import { isEmpty, sortBy } from 'lodash';
import { MigrationTypes } from '../../common/model/migration-types';

const UPLOAD_FILE_SIZE_LIMIT = 1024 * 1024 * 3
const FileActions = {
  IMPORT: 'import',
  EXPORT: 'export'
}

@Component({
  selector: 'digital-platform-migrate-data',
  templateUrl: './migrate-data.component.html',
  styleUrls: ['./migrate-data.component.scss']
})
export class MigrateDataComponent implements OnInit, OnDestroy {
  FileActions = FileActions
  formData: UntypedFormGroup;
  radioBtnControl = new UntypedFormControl();
  selected = '';
  selectedOption: string;
  selectedType: string
  fileLimit = false;
  dataLoaded: boolean;
  dataType = []
  status = UserJourneyStatus.PUBLISHED;

  fileExtn = '*';
  file1;
  uploadedFileExtn = '';
  statusbar = { message: '', visible: false };
  statusTimeout;
  maxSize = 5;
  @ViewChild('rufUploadInst', { static: false }) rufUploadInst;
  selectedImport: boolean;
  selectedExport: boolean;
  moduleUploadDocTypeMetaLoadSubscription: Subscription
  currentSelectedPath = 'file'
  showExportToGsc = false
  MigrationTypes = MigrationTypes

  dynamicSegmentItems: RufDynamicMenubarItem[] = [
    { label: 'File', path: 'file' },
    { label: 'GSC', path: 'gsc' },
  ]

  constructor(
    private fileService: UploadService,
    private ngxFileServerService: FileSaverService,
    private utilService: UtilityService,
    private appService: AppService,
    private router: Router,
    private spinnerService: SpinnerService,
    private dialogService: MatDialog,
    private authService: AdminUiAuthService
  ) {
    this.moduleUploadDocTypeMetaLoadSubscription = this.appService.subscribeToModuleLoading(this.moduleUploadDocTypeMetaLoadSubscribe.bind(this))
  }

  ngOnInit(): void {
    this.dataLoaded = true;
    this.formData = new UntypedFormGroup({
      type: new UntypedFormControl('', Validators.required),
    });
  }

  moduleUploadDocTypeMetaLoadSubscribe(moduleConfigService: ModuleConfigService) {
    if (moduleConfigService.getUploadDocumentMeta()?.docType) {
      this.dataType.push({
        value: moduleConfigService.getUploadDocumentMeta()?.docType,
        viewValue: moduleConfigService.getUploadDocumentMeta()?.docTypeDisplayName,
        orderNo: MODULE_IN_MENU_ORDER_BY_MODULE_PREFIX_MAP[moduleConfigService.getModulePrefix()]
      })

      this.dataType = sortBy([...this.dataType], ['orderNo'])
    }
  }

  selectOption(data) {
    this.selected = data;
  }

  isUserPublisher(): boolean {
    return this.authService.isAdminPublisher()
  }

  saveFile(event: File) {
    if (event) {
      let extArray = [];
      extArray = this.file1.name.split('.');
      this.file1['extension'] = extArray[extArray.length - 1].toLowerCase();

      //Filesize validation
      this.file1.fileLimit = this.file1.size > UPLOAD_FILE_SIZE_LIMIT
    }
  }

  saveFileApiCall() {
    const docType = this.selectedType
    const formData = new FormData();
    formData.append("file", this.file1, this.file1.name);
    this.dataLoaded = false;
    this.fileService.upload(formData, docType).subscribe({
      next: data => {
        this.dataLoaded = true;
        this.selected = '';
        this.file1 = null;
        this.utilService.showSuccessMessage(SAVE_IMPORT_DATA);

        this.router.navigate([this.appService.getLoadedModuleConfigByFileUploadDocumentType(docType)?.getHistoryUrl()])
      },
      error: () => {
        this.dataLoaded = true;
      }
    })
  }

  isNotSupportedByType(selectedMigrationType: MigrationTypes): boolean {
    const docType = this.selectDataType.value
    if (isEmpty(docType)) {
      return true
    }

    const moduleConfig = this.appService.getLoadedModuleConfigByFileUploadDocumentType(docType)
    if (!moduleConfig) {
      return true
    }

    if (!moduleConfig.getUploadDocumentMeta()) {
      return true
    }

    let supported = false
    for (const mType of moduleConfig.getUploadDocumentMeta().migrationTypesSupported) {
      if (selectedMigrationType === mType) {
        supported = true
        break
      }
    }

    return !supported
  }

  resetFile() {
    this.file1 = null;
  }

  handleDeleteFile($event) {
    this.file1 = null;
  }

  handleAbortUpload($event) {
    this.file1 = null;
  }

  typeSelectionChange(selectedValue) {
    this.selectedType = selectedValue;
  }

  async downloadFile() {
    const docType = this.selectedType
    const publishedDoc: any = await lastValueFrom(this.fileService.download(docType))
    const blob = new Blob([JSON.stringify(publishedDoc)], { type: "application/json" })
    const filename = `${this.selectedType}_v${publishedDoc.version}_export_${Date.now()}`
    this.ngxFileServerService.save(blob, filename)
  }

  get selectDataType() { return this.formData.get('type'); }

  handleKeyDown(data: string): void {
    this.selectedImport = data.toLowerCase() === FileActions.IMPORT
    this.selectedExport = data.toLowerCase() === FileActions.EXPORT
    this.selectOption(data);
  }

  changeRadioOptions() {
    if (this.radioBtnControl.value === RADIO_BUTTON_EXPORT_VALUE) {
      this.selectOption(FileActions.EXPORT);
    } else {
      this.selectOption(FileActions.IMPORT);
    }
  }

  async importFromGsc(): Promise<void> {
    const spinnerRef = this.spinnerService.open()
    try {
      const moduleConfigService = this.appService.getLoadedModuleConfigByFileUploadDocumentType(this.selectedType)
      const result = await lastValueFrom(moduleConfigService.getWorkflowDataService().importWorkflowItem())

      if (result) {
        this.router.navigate([moduleConfigService.getHistoryUrl()], { queryParams: { status: UserJourneyStatus.IMPORTED } })
        this.utilService.showSuccessMessage('Document imported ssuccessfully!')
      } else {
        this.utilService.showWarningMessage('Document in sync. Nothing has been imported!')
      }
    } catch (e) {
      this.utilService.showErrorMessage('Document import faield!')
    } finally {
      spinnerRef.close()
    }
  }

  async exportToGsc(): Promise<void> {
    const exportConfirmRef = this.dialogService.open(SimpleDialogComponent, {
      data: {
        title: `Are you sure you know what you're doing?`,
        message: 'By confirming this action you will export currently published version to GSC System and override existing data. Are you sure?',
        actions: ['Not sure, cancel', 'Continue']
      }
    })

    exportConfirmRef.beforeClosed().subscribe(async (action: string) => {
      if (action?.toLowerCase() === 'continue') {
        const wfDataService = this.appService.getLoadedModuleConfigByFileUploadDocumentType(this.selectedType)?.getWorkflowDataService()
        if (wfDataService) {
          const spinnerRef = this.spinnerService.open()
          try {
            await lastValueFrom(wfDataService.exportWorkflowItem())
            this.utilService.showSuccessMessage('Questionnaire exported to GSC ssuccessfully!')
          } catch (e) {
            console.error(e)
            this.utilService.showErrorMessage('Questionnaire export faield!')
          } finally {
            spinnerRef.close()
          }
        }
      }
    })
  }

  @HostListener('document:keydown.shift.control.e', ['$event'])
  toggleShowExport(event: any): void {
    this.showExportToGsc = !this.showExportToGsc
  }

  ngOnDestroy(): void {
    this.moduleUploadDocTypeMetaLoadSubscription.unsubscribe()
  }
}

