import { Component, EventEmitter, Input, Output } from "@angular/core"
import { RufDynamicSidemenuItem } from "@ruf/shell"

@Component({
  selector: 'digital-platform-side-menu',
  templateUrl: './side-menu.compontent.html'
})
export class SideMenuComponent {
  @Input()
  items: RufDynamicSidemenuItem[] = []
  @Input()
  selectedPath: string

  @Output()
  sideMenuSelected = new EventEmitter<string>()

  selectChangeHandler(path: string) {
    this.selectedPath = path
    this.sideMenuSelected.emit(path)
  }
}