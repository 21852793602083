import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { first } from 'rxjs'
import { NODE_TYPE } from '../../model/node-type'
import { getSlugValidator } from '../../utils/formly.validators'
import { updateField } from '../forms/formly.utils'
import { AddNodeFormBuilderService } from './add-node-form-builder.service'
import { QuestionnaireAddComponent } from './questionnaire-add-node.component'

const _updateField = (component: QuestionnaireAddComponent) => {
  updateField('type', component.fields, {
    hooks: {
      onInit: (field: FormlyFieldConfig) => {
        field.formControl.valueChanges.pipe(first()).subscribe((value) => {
          forms[value](component)
        })
      },
    },
  })
  updateField('slug', component.fields, {
    validators: {
      validation: [getSlugValidator(component.data.treeData, undefined, component.data.additionalInvalidSlugs)],
    },
  })
}

const forms = {
  leaf: (component: QuestionnaireAddComponent) => {
    component.form = new UntypedFormGroup({})
    component.fields = component.formBuilderService.build({ type: NODE_TYPE.LEAF })
    _updateField(component)
    updateField('control.action.payload', component.fields, {
      props: {
        required: true,
      },
    })
    component.model = JSON.parse(JSON.stringify({ ...common, ...leaf }))
  },
  branch: (component: QuestionnaireAddComponent) => {
    component.form = new UntypedFormGroup({})
    component.fields = component.fields = component.formBuilderService.build({ type: NODE_TYPE.BRANCH })
    _updateField(component)
    component.model = JSON.parse(JSON.stringify({ ...common, ...branch }))
  },
}

const common = {
  slug: '',
  control: {
    control_type: '',
    label: '',
    tooltip: '',
    icon: '',
    action: {
      action: { action_type: '', payload: '' },
    },
  },
}

const branch = {
  type: NODE_TYPE.BRANCH,
  question: '',
  question_description: '',
  need_help: {
    content: [],
    actions: [{ action_type: '', payload: '' }],
  },
}

const leaf = {
  type: 'leaf',
  catalog_id: null,
}

@Injectable()
export class QuestionnaireAddNodeDefaultFormBuilderService extends AddNodeFormBuilderService {
  onPostInit(component: QuestionnaireAddComponent): void {
    forms.branch(component)
    updateField('need_help.content', component.fields, { props: { rows: 0 } }) // don't add need_help nodes since we don't know how many childere will be there
  }

  onPostChanges(component: QuestionnaireAddComponent): void {
    updateField('slug', component.fields, {
      validators: {
        validation: [getSlugValidator(component.data.treeData, undefined, component.data.additionalInvalidSlugs)],
      },
    })
  }
}
