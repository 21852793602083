import { NestedTreeControl } from '@angular/cdk/tree'
import { AfterViewInit, Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatTreeNestedDataSource } from '@angular/material/tree'
import { Subject } from 'rxjs'
import { flattenByKey } from '../../utils/collection.utils'
import { TreeNodeEditActions } from '../../../shared//tree-node-edit.actions'
import { size } from 'lodash'

interface itemNode {
  label: string
  children?: []
}
@Component({
  selector: 'digital-platform-user-journey-delete',
  templateUrl: './user-journey-delete.component.html',
  styleUrls: ['./user-journey-delete.component.scss'],
})
export class UserJourneyDeleteComponent implements OnInit, AfterViewInit {
  treeControl = new NestedTreeControl<itemNode>((node) => node[this.meta.childrenKey])
  dataSource = new MatTreeNestedDataSource<itemNode>()
  searchForm: UntypedFormGroup
  allSelectedNodes = []
  flattendDataSrc
  showTree = false
  isRootDisabled = true
  @ViewChildren('leafCheckboxes') private leafCheckboxes: QueryList<any>
  @ViewChildren('branchCheckboxes') private branchCheckboxes: QueryList<any>
  actionSubscription = new Subject()
  meta: any = {}

  constructor(private dialogRef: MatDialogRef<UserJourneyDeleteComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.hasOwnProperty('isRootDisabled')) {
      this.isRootDisabled = data.isRootDisabled
    }
  }

  hasChild = (_: number, node: itemNode) => !!node?.children && node?.children?.length > 0

  ngAfterViewInit() {
    this.dialogRef.afterOpened().subscribe((data) => {
      this.expandFirstNode()
    })
  }

  ngOnInit(): void {
    this.meta = this.data?.meta
    if (!this.data?.isSingleLevelNodeStructure) {
      this.flattendDataSrc = flattenByKey(
        this.data?.activeItems[this.meta?.childrenKey],
        this.meta?.childrenKey
      ).filter((el) => this.meta.label(el))
      this.dataSource.data = [this.data?.activeItems]
    } else {
      this.flattendDataSrc = this.data?.activeItems
      this.dataSource.data = this.data?.activeItems
    }

    this.searchForm = new UntypedFormGroup({
      searchString: new UntypedFormControl(''),
    })
  }

  isNodeChecked(event, data) {
    if (event.checked) {
      this.allSelectedNodes.push({
        id: data[this.meta.idKey],
      })
    } else if (!event.checked) {
      this.allSelectedNodes.filter((element, index, array) => {
        if (element.id === data[this.meta.idKey]) {
          array.splice(index, 1)
        }
      })
    }
  }

  deletePermanently() {
    this.actionSubscription.next({ action: TreeNodeEditActions.DELETE, deletedNodeIds: this.allSelectedNodes })
  }

  isNodeDisabled(node: any) {
    if (!node.type) {
      return true
    }
    if (node.type == 'root' && this.isRootDisabled) {
      return true
    }
    return false
  }

  searchTreeNodes(searchStringVal: string) {
    this.treeControl.collapseAll()
    if (searchStringVal.length >= 3) {
      this.resetCheckboxValues()
      this.treeControl.dataNodes = this.dataSource.data
      // This loop will check in active stream flattened object for all relative matched node(s) label based on search field.
      const searchNode = this.flattendDataSrc.filter((node) =>
        this.meta.label(node).toLowerCase().includes(searchStringVal.toLowerCase())
      )
      this.showTree = searchNode.length === 0
      if (!this.data?.isSingleLevelNodeStructure) {
        searchNode.forEach((searchNode) => {
          const parentHeirarchy = this.getAncestorsForSearchNodes(this.dataSource.data, this.meta.label(searchNode))
          if (parentHeirarchy && parentHeirarchy.length > 0) {
            parentHeirarchy.forEach((element) => {
              this.treeControl.expand(element)
            })
          }
        })
      }
    } else {
      this.showTree = false
      this.expandFirstNode()
    }
  }

  resetFormValues(event: any) {
    if (event.clientX === 0 && event.clientY === 0) {
      document.getElementById('search').click()
      return false
    } else {
      this.searchForm.reset()
      this.treeControl.collapseAll()
      this.expandFirstNode()
      this.resetCheckboxValues()
      this.showTree = false
    }
  }

  resetCheckboxValues() {
    this.allSelectedNodes = []
    const leafCheckboxes = this.leafCheckboxes.toArray()
    const branchCheckboxes = this.branchCheckboxes.toArray()
    if (leafCheckboxes.length > 0) {
      leafCheckboxes.forEach((el) => {
        el.checked = false
      })
    }
    if (branchCheckboxes.length > 0) {
      branchCheckboxes.forEach((el) => {
        el.checked = false
      })
    }
  }

  getAncestorsForSearchNodes(sourceData: any, searchLabel: string) {
    if (size(sourceData) > 0) {
      for (let i = 0; i < sourceData.length; i++) {
        if (this.meta.label(sourceData[i]) === searchLabel) {
          return [sourceData[i]]
        }
        const nodes = this.getAncestorsForSearchNodes(sourceData[i][this.meta.childrenKey], searchLabel)

        if (nodes && nodes.length >= 0) {
          nodes.unshift(sourceData[i])
          return nodes
        }
      }
    }
    return null
  }

  cancel() {
    this.actionSubscription.next({ action: TreeNodeEditActions.CLOSE })
  }

  getObservable() {
    return this.actionSubscription.asObservable()
  }

  expandFirstNode() {
    const node = this.dataSource.data[this.dataSource.data.length - 1]
    this.treeControl.expand(node)
  }
}
