<ruf-dialog-panel class="admin-ui-dialog" rufId="simple_dialog">
  <ruf-dialog-header rufId="simple_dialog_header" class="dialog-header" #header>
    {{ data.title }}
  </ruf-dialog-header>
  <ruf-dialog-content rufId="simple_dialog_content" rufScrollbar>
    <p *ngIf="!isMultilineMessage()" class="dialog-content adminui">
      {{ data.message }}
    </p>
    <p *ngIf="isMultilineMessage()" class="adminui">
      <span *ngFor="let msg of data.message" class="dialog-content" style="display: block;">
        {{ msg }}
      </span>
    </p>
  </ruf-dialog-content>
  <ruf-dialog-footer fxLayout="column" fxLayout.gt-sm="row" rufId="simple_dialog_footer" fxLayoutGap="7px"
    class="dialog-footer">
    <div *ngFor="let action of data.actions; let i = index">
      <button *ngIf="i < data.actions.length-1" mat-stroked-button fisStyle color="primary" rufId
        (click)="close(action)">
        {{ action }}
      </button>
      <button *ngIf="i === data.actions.length-1" mat-raised-button fisStyle color="primary" rufId
        (click)="close(action)">
        {{ action }}
      </button>
    </div>
  </ruf-dialog-footer>
</ruf-dialog-panel>