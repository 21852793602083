<ruf-dialog-panel [closeIconTitle]="'Close'">
    <ruf-dialog-header #header>Delete Node
        <hr class="admin-ui-divider">
        <form [formGroup]="searchForm">
            <div fxLayout="column" fxLayoutGap="17px">
                <div class="delete-dialog-form-row">
                    <mat-form-field fisStyle appearance="outline" class="user-journey-form-field">
                        <mat-icon class="admin-search-icon" fisStyle fontSet="fisfont" fontIcon="fis-icon-search"
                            matPrefix>
                        </mat-icon>
                        <input id="searchString" #searchField matInput formControlName="searchString"
                            placeholder="Search Node(s)" (input)="searchTreeNodes(searchField.value)">
                    </mat-form-field>
                    <button class="reset-button" mat-stroked-button fisStyle color="primary"
                        (click)="resetFormValues($event)" (keydown.enter)="resetFormValues($event)">
                        Reset</button>
                </div>
            </div>
        </form>
    </ruf-dialog-header>
    <ruf-dialog-content>
        <div>
            <mat-tree *ngIf="!showTree" [dataSource]="dataSource" [treeControl]="treeControl"
                class="admin-ui-nested-tree">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                    <mat-checkbox fisStyle (change)="isNodeChecked($event,node)" #leafCheckboxes>
                        <div class="marked-content"
                            innerHTML="{{meta?.label(node) | highlight:searchForm.controls.searchString.value}}">
                        </div>
                    </mat-checkbox>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <div class="mat-tree-node">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + meta?.label(node)">
                            <div class="icon-centered">
                                <ruf-labeled-icon fontSet="fisfont"
                                    [iconClass]="treeControl.isExpanded(node) ? 'fis-icon-md  fis-icon-chevron' :'fis-icon-md  fis-icon-chevron fis-icon-rotate-270'"
                                    fontIcon="fis-icon-chevron"></ruf-labeled-icon>
                            </div>
                        </button>
                        <!-- This is the tree node template for Branch nodes -->
                        <mat-checkbox fisStyle (change)="isNodeChecked($event,node)" #branchCheckboxes
                            [disabled]="isNodeDisabled(node)">
                            <div class="marked-content"
                                innerHTML="{{meta?.label(node) | highlight:searchForm.controls.searchString.value}}">
                            </div>
                        </mat-checkbox>
                    </div>
                    <div [class.admin-ui-nested-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </div>
                </mat-nested-tree-node>
            </mat-tree>
        </div>
    </ruf-dialog-content>
    <ruf-dialog-footer>
        <div class="delete-dialog-footer">
            <button type="button" color="primary" class="delete-dialog-cancel-btn" fisStyle mat-stroked-button
                (click)="cancel()" (keydown.enter)="cancel()">Cancel</button>
            <button type="submit" [disabled]="allSelectedNodes.length === 0" color="primary"
                class="delete-dialog-delete-btn" fisStyle mat-raised-button (click)="deletePermanently()"
                (keydown.enter)="deletePermanently()">Delete Permanently</button>
        </div>
    </ruf-dialog-footer>
</ruf-dialog-panel>