import { Injectable } from "@angular/core"
import { UserRoles } from "@digital-platform/model"
import { find } from "lodash"
import { lastValueFrom, ReplaySubject, Subscription, switchMap } from "rxjs"
import { AppConfig } from "./common/model/app.config"
import { ModuleNames } from "./common/model/constants"
import { ModuleConfigService } from "./common/services/module-config.service"
import { AdminUiAuthService } from "./service-api/admi-ui-auth.service"

const APP_CONFIG = {
  migrateDataUrl: '/migrate-data'
}

const MODULE_ROLE_MAP = {
  [ModuleNames.GBT]: UserRoles.GSC_ADMINUI_MODULE_GBT_USER
}

@Injectable()
export class AppService {
  moduleRegistrationPool = {}
  moduleUploadDocTypeMetaLoadSubject = new ReplaySubject<ModuleConfigService>()
  moduleLoad$ = this.moduleUploadDocTypeMetaLoadSubject.asObservable()

  constructor(private adminUiAuthService: AdminUiAuthService) { }

  async register(moduleConfig: ModuleConfigService): Promise<void> {
    await lastValueFrom(this.adminUiAuthService.getLoadUserRoleObs()) // only waiting user auth to be loaded
    const modulePrefix = moduleConfig.getModulePrefix();
    if (!MODULE_ROLE_MAP[modulePrefix] || this.adminUiAuthService.getAdminRoles()?.includes(MODULE_ROLE_MAP[modulePrefix])) {
      this.moduleRegistrationPool[modulePrefix] = moduleConfig;
      const hasWebhookUser = this.adminUiAuthService.hasWebhookUser();
      const isWebhookGeneralUser = this.adminUiAuthService.isWebhookGeneralUser();

      if (hasWebhookUser) {
        if (isWebhookGeneralUser || modulePrefix === "webhook") {
          // show webhook module with other modules or only webhook module
          this.moduleUploadDocTypeMetaLoadSubject.next(moduleConfig);
        }
      } else if (modulePrefix !== "webhook") {
        // show other than webhook module
        this.moduleUploadDocTypeMetaLoadSubject.next(moduleConfig);
      }
    }
  }

  getRegistredModules(): ModuleConfigService[] {
    return Object.values(this.moduleRegistrationPool)
  }

  subscribeToModuleLoading(observer: (moduleConfigService: ModuleConfigService) => void): Subscription {
    return this.moduleLoad$.subscribe(observer)
  }

  getLoadedModuleConfigByModulePrefix(modulePrefix: string): ModuleConfigService {
    return find(this.moduleRegistrationPool, (mConfig: ModuleConfigService) => mConfig.getModulePrefix() === modulePrefix)
  }

  getLoadedModuleConfigByFileUploadDocumentType(uplodDocType: string): ModuleConfigService {
    return find(this.moduleRegistrationPool, (mConfig: ModuleConfigService) => mConfig.getUploadDocumentMeta()?.docType === uplodDocType)
  }

  getAppConfig(): AppConfig {
    return { ...APP_CONFIG }
  }
}