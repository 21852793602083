import { Component } from '@angular/core'
import { FieldType } from '@ngx-formly/core'

@Component({
  selector: 'digital-platform-formly-field-input',
  styleUrls: ['./toggle-template.scss'],
  templateUrl: './toggle.html',
})
export class FormlyFieldToggleComponent extends FieldType {
  color = 'primary'

  changeToggleval(data: any) {
    // @ts-ignore
    this.field.formControl?.setValue(data.checked)
  }

  isChecked() {
    // @ts-ignore
    return this.field.formControl.value
  }

  showValue(): boolean {
    return false
  }
}
