import { forwardRef, Inject, Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router"
import { UserRoles } from "@digital-platform/model"
import { Observable } from "rxjs"
import { AdminUiAuthService } from "../service-api/admi-ui-auth.service"

@Injectable()
export class ResBModuleLoadingGuard implements CanActivate {
  constructor(
    @Inject(forwardRef(() => AdminUiAuthService)) private adminUiAuthService: AdminUiAuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const canActivate = this.adminUiAuthService.getAdminRoles().includes(UserRoles.GSC_ADMINUI_MODULE_RESB_USER)
    !canActivate && this.router.navigate(['/'])
    return canActivate
  }
}