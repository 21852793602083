<div class="formly-form-file-input-component-container">
  <input hidden type="file" #fileUploader (change)="onFileChange($event)" />
  <mat-label class="label" *ngIf="!to.hideLabel">
    {{ field.props.label }}
  </mat-label>
  <button type="button" class="file-upload-button" aria-label="choose file" mat-button (click)="fileUploader.click()">
    <ruf-labeled-icon fontSet="fisfont" iconClass="fis-icon-sm ruf-foreground-primary" fontIcon="fis-icon-add">
      Attach File
    </ruf-labeled-icon>
  </button>
  <formly-validation-message [field]="field" class="validation-error-msg"></formly-validation-message>
  <img *ngIf="formControl.value" [src]="formControl.value" height="100px" width="100px" alt="not available" />
</div>
