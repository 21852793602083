import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core"

const UPLOAD_FILE_SIZE_LIMIT = 1024 * 1024 * 3

@Component({
  selector: 'digital-platform-text-file-parser',
  templateUrl: './text-file-parser.component.html',
})
export class PlainTextFileParserComponent implements OnDestroy {
  @Input()
  disabled: boolean
  @Input()
  label = 'Import'
  @Input()
  fileExtensions = '.json,.JSON'
  @Input()
  class: string

  @Output()
  fileParsed = new EventEmitter<any>()

  @ViewChild('file')
  fileInput: any

  file: any
  fileReader: FileReader

  constructor() {
    this.fileReader = new FileReader()
    this.fileReader.addEventListener('load', this.readFileHandler.bind(this))
  }

  readFileHandler(event: any): void {
    this.fileParsed.emit(JSON.parse(this.fileReader.result as string))
  }

  fileSelectionChange(event: any): void {
    this.file = event.target.files[0]
    this.fileReader.readAsText(this.file)
    this.file = undefined
    this.fileInput.nativeElement.value = ''
  }

  ngOnDestroy(): void {
    this.fileReader.removeAllListeners()
  }
}