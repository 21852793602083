import { nanoid } from 'nanoid';
import { DocFeed } from './doc-feed';
import { LineOutputUtils } from './line-output-utils';
import { sortObjectProperties } from './object-utils';
import { AbstractJsonDiffPatch } from './abstract-jsondiffpatc';
import { Delta } from 'jsondiffpatch';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export class FullLineAnalyzer extends AbstractJsonDiffPatch {
  getDelta(jsonObject1: any, jsonObject2: any, unprocessingProps?: any[]): Delta | undefined {
    if (!jsonObject1) {
      jsonObject1 = {};
    }
    if (!jsonObject2) {
      jsonObject2 = {};
    }

    // sort obj props
    jsonObject1 = sortObjectProperties(jsonObject1);
    jsonObject2 = sortObjectProperties(jsonObject2);

    if (unprocessingProps) {
      this.removeUnprocessingProps(jsonObject1, unprocessingProps);
      this.removeUnprocessingProps(jsonObject2, unprocessingProps);
    }

    const result = this.getJsondiffInstance().diff(jsonObject1, jsonObject2);
    return result;
  }

  diff(jsonObject1: any, jsonObject2: any, unprocessingProps?: any[]) {
    const result = this.getDelta(jsonObject1, jsonObject2, unprocessingProps);

    const [obj1Result, obj2Result, delta] = this.generateOutput(jsonObject1, jsonObject2, result);

    return [obj1Result, obj2Result, delta];
  }

  generateOutput(jsonObject1: any, jsonObject2: any, delta: Delta | undefined) {
    const idfiedDeltaMap = (delta && this.idfyDalta(delta)) || {};

    const obj1Result = this.generateObjOut(jsonObject1, delta, DocFeed.SOURCE, idfiedDeltaMap);
    const obj2Result = this.generateObjOut(jsonObject2, delta, DocFeed.TARGET, idfiedDeltaMap);

    this.addEmptyLines(obj1Result, obj2Result);

    return [obj1Result, obj2Result, delta];
  }

  generateObjOut(jsonObj: any, delta: Delta | undefined, docFeed: DocFeed, idfiedDeltaMap: { [key: string]: any }) {
    const objResult = [];
    const openChar = Object.prototype.toString.call(jsonObj) === '[object Object]' ? '{' : '[';
    const closeChar = Object.prototype.toString.call(jsonObj) === '[object Object]' ? '}' : ']';
    objResult.push({
      ctx: true,
      out: openChar,
      changeType: undefined,
      path: [],
    });
    this.objOut(jsonObj, delta, objResult, [], docFeed, idfiedDeltaMap);
    objResult.push({
      ctx: true,
      out: closeChar,
      changeType: undefined,
      path: [],
    });

    return objResult;
  }

  objOut(
    obj: any,
    diff: Delta | undefined,
    lines: any[],
    path: string[],
    docFeed: DocFeed,
    idfiedDeltaMap: any,
    parentChangeType?: string | undefined
  ) {
    const entries: Entries<any> = Object.entries(obj);
    for (let i = 0; i < entries.length; i++) {
      const [key, value] = entries[i];
      const objPath = [...path, key];
      const ctx = this.existsPath(diff, path);
      if (Object.prototype.toString.call(value) === '[object Object]') {
        const changeType = parentChangeType || this.getLineChangeType(key, value, diff, objPath, docFeed);
        lines.push({
          ctx,
          path,
          changeType,
          id: changeType && this.getDeltaId(idfiedDeltaMap, objPath),
          out: `${this.getTabs(objPath.length)}"${key}": {`,
        });
        this.objOut(value, diff, lines, objPath, docFeed, idfiedDeltaMap, changeType);
        lines.push({
          ctx,
          path,
          changeType,
          id: changeType && this.getDeltaId(idfiedDeltaMap, objPath),
          out: `${this.getTabs(objPath.length)}}`,
        });
      } else if (Object.prototype.toString.call(value) === '[object Array]') {
        const changeType = parentChangeType || this.getLineChangeType(key, value, diff, objPath, docFeed);
        lines.push({
          ctx,
          path,
          changeType,
          id: changeType && this.getDeltaId(idfiedDeltaMap, objPath),
          out: `${this.getTabs(objPath.length)}"${key}": [`,
        });

        this.arrOut(value, diff, lines, objPath, docFeed, idfiedDeltaMap, changeType);

        lines.push({
          ctx,
          path,
          changeType,
          id: changeType && this.getDeltaId(idfiedDeltaMap, objPath),
          out: `${this.getTabs(objPath.length)}]`,
        });
      } else {
        this.primOut(key, value, diff, lines, [...path, key], docFeed, idfiedDeltaMap, parentChangeType);
        if (i === entries.length - 1) {
          // remove trailing comma
          lines[lines.length - 1].out = lines[lines.length - 1].out.slice(0, lines[lines.length - 1].out.length - 1);
        }
      }
    }
  }

  arrOut(arr: any[], diff: Delta | undefined, lines: any[], path: string[], docFeed: DocFeed, idfiedDeltaMap: any, parentChangeType?: string) {
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      const arrElemPath = [...path, i.toString()];
      const ctx = this.existsPath(diff, arrElemPath);
      const changeType = parentChangeType || this.getArrLineChangeType(i, item, diff, arrElemPath, docFeed);
      if (Object.prototype.toString.call(item) === '[object Object]') {
        lines.push({
          ctx,
          path: arrElemPath,
          changeType,
          id: changeType && this.getDeltaId(idfiedDeltaMap, arrElemPath),
          out: `${this.getTabs(arrElemPath.length)}{`,
        });
        this.objOut(item, diff, lines, arrElemPath, docFeed, idfiedDeltaMap, changeType);
        lines.push({
          ctx,
          path: arrElemPath,
          changeType,
          id: changeType && this.getDeltaId(idfiedDeltaMap, arrElemPath),
          out: `${this.getTabs(arrElemPath.length)}}${i === arr.length - 1 ? '' : ','}`,
        });
      } else {
        lines.push({
          ctx,
          path: arrElemPath,
          changeType,
          id: changeType && this.getDeltaId(idfiedDeltaMap, arrElemPath),
          out: `${this.getTabs(arrElemPath.length)}${this.primValOut(item)}${i === arr.length - 1 ? '' : ','}`,
        });
      }
    }
  }

  getTabs(len: number) {
    return LineOutputUtils.getTabs(len);
  }

  primOut(
    key: any,
    value: any,
    diff: Delta | undefined,
    out: any[],
    path: string[],
    docFeed: DocFeed,
    idfiedDeltaMap: any,
    parentChangeType?: string
  ) {
    const changeType = parentChangeType || this.getLineChangeType(key, value, diff, path, docFeed);
    const ctx = this.existsPath(diff, path);
    out.push({
      ctx,
      path,
      changeType,
      id: changeType && this.getDeltaId(idfiedDeltaMap, path),
      out: `${this.getTabs(path.length)}"${key}": ${this.primValOut(value)},`,
    });
  }

  primValOut(value: any) {
    let out = '';
    if (Object.prototype.toString.call(value) === '[object String]') {
      out = `"${value}"`;
    } else {
      out = `${value}`;
    }

    return out;
  }

  getLineChangeType(key: any, value: any, diff: any, path: string[], docFeed: DocFeed) {
    let obj = diff;
    for (const step of path) {
      obj = obj ? obj[step] : undefined;
    }

    const typeofDiff = Object.prototype.toString.call(obj);
    if (typeofDiff === '[object Array]') {
      // regular object/primitive
      switch (obj.length) {
        case 1:
          return docFeed === DocFeed.TARGET ? 'A' : undefined;
        case 2:
          return 'M';
        case 3:
          return docFeed === DocFeed.SOURCE ? 'R' : undefined;
      }
    } else {
      return;
    }

    return
  }

  getArrLineChangeType(idx: number, value: any, diff: Delta | undefined, path: string[], docFeed: DocFeed) {
    if (!diff) {
      return
    }

    let obj = diff;
    for (let i = 0; i < path.length - 1; i++) {
      obj = obj ? obj[path[i]] : undefined;
    }

    const typeofDiff = Object.prototype.toString.call(obj);
    if (
      typeofDiff === '[object Object]' &&
      (Object.prototype.toString.call(obj[idx]) === '[object Array]' ||
        Object.prototype.toString.call(obj[`_${idx}`]) === '[object Array]')
    ) {
      // array
      if (obj[idx] && obj[`_${idx}`]) {
        return 'M';
      } else if (obj[idx] && docFeed === DocFeed.TARGET) {
        return 'A';
      } else if (obj[`_${idx}`] && docFeed === DocFeed.SOURCE) {
        return 'R';
      }
    }

    return
  }

  idfyDalta(delta: any, parentKey?: string, idfiedDeltaMap: { [key: string]: any } = {}) {
    const entries: Entries<any> = Object.entries(delta);
    for (const [key, value] of entries) {
      let objKey = '';
      if (parentKey) {
        objKey = parentKey + '/';
      }
      objKey = objKey + key;

      if (Object.prototype.toString.call(value) === '[object Array]' && [1, 2, 3].includes((value as []).length)) {
        idfiedDeltaMap[objKey] = nanoid();
      } else if (Object.prototype.toString.call(value) === '[object Object]') {
        if (value['_t']) {
          this.idfyArrDelta(value, idfiedDeltaMap, objKey);
        } else {
          this.idfyDalta(value, objKey, idfiedDeltaMap);
        }
      }
    }

    return idfiedDeltaMap;
  }

  idfyArrDelta(delta: any, idfiedDeltaMap: { [key: string]: any } = {}, objKey: string) {
    const idMap: { [key: string]: any } = {};
    for (const [key] of Object.entries(delta)) {
      if (key === '_t') {
        // skip this
      } else if (key.startsWith('_') && idMap[key.slice(1)]) {
        idMap[key] = idMap[key.slice(1)];
      } else {
        idMap[key] = nanoid();
      }

      if (key !== '_t') {
        idfiedDeltaMap[objKey + '/' + key] = idMap[key];
        if (key.startsWith('_')) {
          /**
           * if the item from the array is removed it will be at '_index' key,
           * but in the path[] the index are always non-zero integers.
           * So we need to check in the map for both 'index' and '_index' kyes
           **/
          idfiedDeltaMap[objKey + '/' + key.slice(1)] = idMap[key];
        }
      }
    }
  }

  getDeltaId(idfiedDeltaMap: any, path: string[]) {
    for (let i = path.length; i > 0; i--) {
      const p = path.slice(0, i).join('/');
      if (idfiedDeltaMap[p]) {
        return idfiedDeltaMap[p];
      }
    }

    return;
  }

  addEmptyLines(obj1Result: any[], obj2Result: any[]) {
    const leftRemovedIndexes: Map<number, any> = new Map<number, any>();
    const rightAddedIndexes: Map<number, any> = new Map<number, any>();

    this.createShiftPositionMap(obj1Result, 'R', leftRemovedIndexes);
    this.createShiftPositionMap(obj2Result, 'A', rightAddedIndexes);

    this.addEmptyLinesOnChanges(obj1Result, obj2Result, leftRemovedIndexes, rightAddedIndexes);

    const sortedLeftRemovedIndexes = new Map<number, any>([...leftRemovedIndexes.entries()].sort(this.intKeyMapSort));
    const sortedRightAddedIndexes = new Map<number, any>([...rightAddedIndexes.entries()].sort(this.intKeyMapSort));

    // ADDING EMPTY LINES
    // adding empty lines to the left for added items
    {
      let offset = 0
      for (const [key, value] of sortedLeftRemovedIndexes.entries()) {
        const j = this.getPrecendenceOffsetRight(sortedRightAddedIndexes, key, offset)

        let currentOffsetIndex = key + j;
        if (value.changeType === 'M') {
          while (
            obj2Result[currentOffsetIndex].changeType &&
            currentOffsetIndex > 0 &&
            obj2Result[currentOffsetIndex].id === obj1Result[currentOffsetIndex - 1].id
          ) {
            currentOffsetIndex++;
          }
        }
        obj2Result.splice(currentOffsetIndex, 0, ...this.getEmptyObjects(value.len));

        offset += value.len
      }
    }

    // adding empty lines to the right for removed items
    {
      let offset = 0
      for (const [key, value] of sortedRightAddedIndexes.entries()) {
        const j = this.getPrecendenceOffsetLeft(sortedLeftRemovedIndexes, key - offset)
        const currentOffsetIndex = key + j;
        obj1Result.splice(currentOffsetIndex, 0, ...this.getEmptyObjects(value.len));

        offset += value.len
      }
    }
  }

  getPrecendenceOffsetRight(indexMap1: Map<number, any>, lineNumber: number, offset: number): number {
    let shift = 0;
    for (const [key, value] of Array.from(indexMap1.entries())) {
      if (key + offset < shift + lineNumber) {
        shift += value.len;
      } else {
        break;
      }
    }

    return shift;
  }

  getPrecendenceOffsetLeft(indexMap1: Map<number, any>, lineNumber: number): number {
    let shift = 0;
    for (const [key, value] of Array.from(indexMap1.entries())) {
      if (key <= shift + lineNumber) {
        shift += value.len;
      } else {
        break;
      }
    }

    return shift;
  }

  createShiftPositionMap(objResult: any[], changeType: string, emptyPositionMap: Map<number, any>) {
    let conseq = 1;
    for (let i = 0; i < objResult.length; i++) {
      objResult[i].idx = i + 1;
      if (objResult[i].changeType === changeType) {
        if (emptyPositionMap.has(i - conseq)) {
          emptyPositionMap.set(i - conseq, {
            ...emptyPositionMap.get(i - conseq),
            len: emptyPositionMap.get(i - conseq).len + 1,
          });
          conseq++;
        } else {
          conseq = 1;
          emptyPositionMap.set(i, { len: conseq, changeType });
        }
      }
    }
  }

  addEmptyLinesOnChanges(
    obj1Result: any[],
    obj2Result: any[],
    leftRemovedIndexes: Map<number, any>,
    rightAddedIndexes: Map<number, any>
  ) {
    const obj1ChangeLengthByKey: Map<string, any> = this.getChangeLengthInLines(obj1Result);
    const obj2ChangeLengthByKey: Map<string, any> = this.getChangeLengthInLines(obj2Result);

    for (const [key, value] of obj1ChangeLengthByKey) {
      if (value.len > obj2ChangeLengthByKey.get(key).len) {
        // add empty lines to the left side at the end of change
        const diff = value.len - obj2ChangeLengthByKey.get(key).len;
        // leftRemovedIndexes.set(value.lineNo + obj2ChangeLengthByKey.get(key).len, { len: diff, changeType: 'M' })
        leftRemovedIndexes.set(obj2ChangeLengthByKey.get(key).lineNo + obj2ChangeLengthByKey.get(key).len, {
          len: diff,
          changeType: 'M',
        });
      } else if (value.len < obj2ChangeLengthByKey.get(key).len) {
        // add empty lines to the right side at the end of change
        const diff = obj2ChangeLengthByKey.get(key).len - value.len;
        // rightAddedIndexes.set(value.lineNo + value.len, { len: diff, changeType: 'M' })
        rightAddedIndexes.set(obj2ChangeLengthByKey.get(key).lineNo + value.len, { len: diff, changeType: 'M' });
      }
    }
  }

  getChangeLengthInLines(objResult: any[]) {
    const obj1ChangeLengthByKey: Map<string, any> = new Map<string, any>();
    for (let i = 0; i < objResult.length; i++) {
      const id = objResult[i].changeType === 'M' ? objResult[i].id : undefined;
      if (id) {
        if (obj1ChangeLengthByKey.has(id)) {
          const old = obj1ChangeLengthByKey.get(id);
          obj1ChangeLengthByKey.set(id, { ...old, len: old.len + 1 });
        } else {
          obj1ChangeLengthByKey.set(id, { lineNo: i, len: 1 });
        }
      }
    }

    return obj1ChangeLengthByKey;
  }

  getEmptyObjects(count: number) {
    const emptyObjArr = [];
    for (let i = 0; i < count; i++) {
      emptyObjArr.push({});
    }
    return emptyObjArr;
  }

  intKeyMapSort(a: any, b: any) {
    const [key1] = a;
    const [key2] = b;
    const k1 = Number(key1);
    const k2 = Number(key2);
    if (k1 > k2) {
      return 1;
    } else if (k1 < k2) {
      return -1;
    }

    return 0;
  }

  existsPath(obj: any, path: any[]) {
    return !!path.reduce((prev, curr) => (prev && prev[curr]) || null, obj);
  }

  removeUnprocessingProps(obj: any, propsToRemove: string[]) {
    if (!obj || !propsToRemove) {
      return;
    }

    for (const s of propsToRemove) {
      delete obj[s];
    }

    for (const [k, v] of Object.entries(obj)) {
      if (v && typeof v === 'object') {
        this.removeUnprocessingProps(v, propsToRemove);
      }
    }
  }
}
