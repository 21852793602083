import { Component } from '@angular/core'
import { FieldType, FieldTypeConfig } from '@ngx-formly/core'

@Component({
  selector: 'digital-platform-formly-field-input',
  template: `
    <div class="ruf-boxed-input-wrapper">
      <mat-form-field boxStyle style="width: 100%">
        <input
          matInput
          [type]="field.props?.type || 'text'"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [placeholder]="field.props.placeholder || ''"
        />
        <formly-validation-message
          *ngIf="field.formControl.touched && field.formControl.invalid"
          [field]="field"
          style="position:relative;color:red;top:1em"
        ></formly-validation-message>
      </mat-form-field>
    </div>
  `,
})
export class FormlyFieldInputComponent extends FieldType<FieldTypeConfig> {}
