import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { AppService } from '../../app.service'
import { ModuleConfigService } from '../../common/services/module-config.service'
import { MODULE_IN_MENU_ORDER_BY_MODULE_PREFIX_MAP } from '../../common/model/app.constants'

const HOME = 'Home'

@Component({
  selector: 'digital-platform-select-module-dropdown',
  templateUrl: './select-module-dropdown.component.html',
  styleUrls: ['./select-module-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectModuleDropdownComponent implements OnChanges {
  @Input()
  selectedPath: string
  title: string
  moduleLoadingSubscription: Subscription
  switchPortalOptions = []

  constructor(
    private router: Router,
    private appService: AppService) {
    this.switchPortalOptions.push({
      label: 'Home',
      path: '/home',
      icon: 'fis-icon-home',
      orderNo: -1
    })
    this.moduleLoadingSubscription = this.appService.subscribeToModuleLoading(this.moduleLoadingSubscribe.bind(this))
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.title = HOME
    if (changes.selectedPath?.currentValue) {
      const urlPath: string = changes.selectedPath.currentValue
      const selectedPath = urlPath.startsWith('/') ? urlPath.substring(1) : urlPath
      const moduleConfigService = this.appService.getLoadedModuleConfigByModulePrefix(selectedPath.split('/')[0])
      if (moduleConfigService) {
        this.title = moduleConfigService.getModuleTitle()
      }
    }
  }

  moduleLoadingSubscribe(moduleConfigService: ModuleConfigService): void {
    const mNavMeta = moduleConfigService.getModuleNavigationMeta()
    this.switchPortalOptions.push({
      label: mNavMeta?.moduleTitle,
      path: mNavMeta?.homeUrl,
      icon: mNavMeta?.moduleIcon,
      orderNo: MODULE_IN_MENU_ORDER_BY_MODULE_PREFIX_MAP[moduleConfigService.getModulePrefix()]
    })
    this.switchPortalOptions.sort((a, b) => {
      if (a.orderNo > b.orderNo) {
        return 1
      } else if (a.orderNo < b.orderNo) {
        return -1
      } else {
        return 0
      }
    })
  }

  routeToLandingPage(path: string, event: any): void {
    if (event.source.selected) {
      this.router.navigate([path])
    }
  }
}
