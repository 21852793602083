import { DragDropModule } from '@angular/cdk/drag-drop'
import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule as AngulaCommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatMomentDateModule } from '@angular/material-moment-adapter'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatTreeModule } from '@angular/material/tree'
import { CustomTemplatesFormlyModule } from '@digital-platform/custom-templates-formly'
import { FormlyModule } from '@ngx-formly/core'
import { FormlyMaterialModule } from '@ngx-formly/material'
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker'
import { RufMaterialModule } from '@ruf/material'
import {
  RufAppCanvasModule,
  RufBannerModule,
  RufDialogPanelModule,
  RufDropdownPanelModule,
  RufDynamicSegmentModule,
  RufDynamicSideMenuModule,
  RufFooterModule,
  RufIconModule,
  RufLabeledIconModule,
  RufLayoutModule,
  RufMenubarModule,
  RufNavbarModule,
  RufPageHeaderModule,
  RufSidemenuModule,
  RufTriggerModule,
} from '@ruf/shell'
import {
  RufDynamicDatatableModule,
  RufFileUploadModule,
  RufLoadingIndicatorModule,
  RufStepperModule,
  RufTimePickerModule,
} from '@ruf/widgets'
import { HexagonalCardsHomeComponent } from '../adminui-home/hexagonal-cards-home/hexagonal-cards-home.component'
import { RactangularCardsHomeComponent } from '../adminui-home/ractangular-cards-home/ractangular-cards-home.component'
import { AddNodeFormBuilderService } from './components/add-node/add-node-form-builder.service'
import { QuestionnaireAddNodeDefaultFormBuilderService } from './components/add-node/questionnaire-add-node-form-default-builder.service'
import { QuestionnaireAddComponent } from './components/add-node/questionnaire-add-node.component'
import { ConfigurableDatatableComponent } from './components/configurable-datatable/configurable-datatable.component'
import { DatatableDiffPanelComponent } from './components/datatable-diff-panel/datatable-diff-panel.component'
import { DatatableErrorPanelComponent } from './components/datatable-error-panel/datatable-error-panel.component'
import { DatatablePreviewComponent } from './components/datatable-preview/datatable-preview.component'
import { DescriptionDialogComponent } from './components/dialogs/description-dialog/description-dialog.component'
import { ErrorDialogComponent } from './components/dialogs/error-dialog/error-dialog.component'
import { FormDialogComponent } from './components/dialogs/form-dialog/form-dialog.component'
import { SimpleDialogComponent } from './components/dialogs/simple-dialog/simple-dialog.component'
import { TreeDataErrorDialogComponent } from './components/dialogs/tree-data-error-dialog/tree-data-error-dialog.component'
import { DraggableTreeviewComponent } from './components/draggable-treeview/draggable-treeview.component'
import { DynamicComponentHostComponent } from './components/dynamic-component-host/dynamic-component-host.component'
import { DynamicDatatableComponent } from './components/dynamic-datatable/dynamic-datatable.component'
import { DynamicTreeViewerComponent } from './components/dynamic-tree-viewer/dynamic-tree-viewer.component'
import { DynamicTreeComponent } from './components/dynamic-tree/dynamic-tree.component'
import { ImportSelectionComponent } from './components/import-selection/import-selection.component'
import { InputSearchComponent } from './components/input-search/input-search.component'
import { LeaveHomeConfirmationComponent } from './components/leave-home-confimration/leave-home-confimration.component'
import { NeedHelpComponent } from './components/need-help/need-help.component'
import { QuestionnaireHistoryComponent } from './components/questionnaire-history/questionnaire-history.component'
import { QuestionnaireModificationTemplateComponent } from './components/questionnaire-modification-template/questionnaire-modification-template.component'
import { QuestionnairePreviewComponent } from './components/questionnaire-preview/questionnaire-preview.component'
import { SnackbarComponent } from './components/snackbar/snackbar.component'
import { PlainTextFileParserComponent } from './components/text-file-parser/text-file-parser.component'
import { UserJourneyDeleteComponent } from './components/user-journey-delete/user-journey-delete.component'
import { UserJourneyEditComponent } from './components/user-journey-edit/user-journey-edit.component'
import { UserJourneyPreviewComponent } from './components/user-journey-preview/user-journey-preview.component'
import { HighlightSearch } from './pipes/highlight-search-string.pipe'
import { AddNodeService } from './services/add-node.service'
import { DeleteNodeService } from './services/delete-node.service'
import { FileObjectService } from './services/file-object.service'
import { QuestionnaireAddNodeService } from './services/questionnaire-add-node.service'
import { QuestionnaireDeleteNodeService } from './services/questionnaire-delete-node.service'
import { QuestionnaireVersionedService } from './services/questionnaire.versioned.service'
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component'
import { HasRoleDirective } from './directives/has-role.directive'

@NgModule({
  declarations: [
    DynamicTreeComponent,
    DynamicTreeViewerComponent,
    DynamicComponentHostComponent,
    QuestionnaireModificationTemplateComponent,
    NeedHelpComponent,
    SpinnerOverlayComponent,
    QuestionnaireHistoryComponent,
    DynamicDatatableComponent,
    SnackbarComponent,
    QuestionnaireAddComponent,
    UserJourneyDeleteComponent,
    UserJourneyEditComponent,
    UserJourneyPreviewComponent,
    QuestionnairePreviewComponent,
    LeaveHomeConfirmationComponent,
    HighlightSearch,
    SimpleDialogComponent,
    ErrorDialogComponent,
    DescriptionDialogComponent,
    InputSearchComponent,
    DatatableDiffPanelComponent,
    DatatableErrorPanelComponent,
    DatatablePreviewComponent,
    ConfigurableDatatableComponent,
    FormDialogComponent,
    DraggableTreeviewComponent,
    PlainTextFileParserComponent,
    ImportSelectionComponent,
    PlainTextFileParserComponent,
    HexagonalCardsHomeComponent,
    RactangularCardsHomeComponent,
    TreeDataErrorDialogComponent,
    HasRoleDirective
  ],
  imports: [
    AngulaCommonModule,
    FlexLayoutModule,
    RufMaterialModule,
    RufLayoutModule,
    RufBannerModule,
    RufFooterModule,
    RufIconModule,
    RufMenubarModule,
    RufNavbarModule,
    RufPageHeaderModule,
    RufDynamicSideMenuModule,
    RufDialogPanelModule,
    RufFileUploadModule,
    MatNativeDateModule,
    RufTimePickerModule,
    RufLoadingIndicatorModule,
    RufSidemenuModule,
    RufStepperModule,
    RufMaterialModule,
    RufDynamicDatatableModule,
    RufDropdownPanelModule,
    RufAppCanvasModule,
    RufLabeledIconModule,
    RufDynamicSegmentModule,
    MatDatepickerModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    RufTriggerModule,
    OverlayModule,
    MatTooltipModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatStepperModule,
    MatListModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatRadioModule,
    CustomTemplatesFormlyModule,
    FormlyMaterialModule,
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    DragDropModule,
    FormlyMatDatepickerModule,
    MatDatepickerModule,
  ],
  providers: [
    FileObjectService,
    QuestionnaireVersionedService,
    DraggableTreeviewComponent,
    PlainTextFileParserComponent,
    { provide: AddNodeService, useClass: QuestionnaireAddNodeService },
    { provide: DeleteNodeService, useClass: QuestionnaireDeleteNodeService },
    { provide: AddNodeFormBuilderService, useClass: QuestionnaireAddNodeDefaultFormBuilderService },
  ],
  exports: [
    DynamicTreeComponent,
    DynamicTreeViewerComponent,
    DynamicComponentHostComponent,
    QuestionnaireModificationTemplateComponent,
    NeedHelpComponent,
    SpinnerOverlayComponent,
    QuestionnaireHistoryComponent,
    DynamicDatatableComponent,
    SnackbarComponent,
    QuestionnairePreviewComponent,
    LeaveHomeConfirmationComponent,
    HighlightSearch,
    SimpleDialogComponent,
    ErrorDialogComponent,
    DescriptionDialogComponent,
    InputSearchComponent,
    DatatableDiffPanelComponent,
    DatatableErrorPanelComponent,
    DatatablePreviewComponent,
    ConfigurableDatatableComponent,
    FormDialogComponent,
    DraggableTreeviewComponent,
    PlainTextFileParserComponent,
    ImportSelectionComponent,
    HexagonalCardsHomeComponent,
    RactangularCardsHomeComponent,
    MatSlideToggleModule,
    MatCheckboxModule,
    TreeDataErrorDialogComponent,
    HasRoleDirective
  ],
})
export class CommonModule {}
