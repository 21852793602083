import { Injectable } from '@angular/core'
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { deleteObjectAtPath, getCurrentPath } from '@digital-platform/json-compare'
import { isEmpty, toPath } from 'lodash'
import { TreeNodeEditActions } from '../../shared/tree-node-edit.actions'
import { DialogService } from '../../shared/user-journey/service/dialog.service'
import { DeleteNodeService } from './delete-node.service'
import { UserJourneyDeleteComponent } from '../components/user-journey-delete/user-journey-delete.component'
import { DeleteNodeData } from '../model/delete-node.data'

@Injectable()
export class QuestionnaireDeleteNodeService implements DeleteNodeService {
  constructor(private dialogService: DialogService) {}

  async deleteNode(deleteData: any): Promise<void> {
    await this.deleteNodeLocal(deleteData)
  }

  async deleteNodeLocal(deleteData: DeleteNodeData) {
    const { treeData, activeItem, meta, isSingleLevelNodeStructure, createNewCallback } = deleteData
    const dialogConfig: MatDialogConfig = {
      role: 'dialog',
      panelClass: ['fis-style', 'overflow-hide', 'admin-delete-node-dialog'],
      data: {
        activeItems: isSingleLevelNodeStructure ? treeData : activeItem,
        meta: meta,
        isSingleLevelNodeStructure: isSingleLevelNodeStructure,
      },
      maxWidth: '40vw',
      minWidth: '40vw',
      maxHeight: '95vh',
    }

    if (isEmpty(dialogConfig.data.activeItems) && !isEmpty(treeData)) {
      // This use case is to handle if the root is an array of deletable root nodes (mega-menu).
      // Modifying the type of the real root nodes and addint them in the artifical root won't affect anyting
      // since the result of the "selecting nodes for deletion" in the DeleteNode Dialog is the list of the __nodeId(s) to be deleted.
      const treeDataForDeletion = JSON.parse(JSON.stringify(treeData))
      if (treeDataForDeletion instanceof Array) {
        for (const item of treeDataForDeletion) {
          item.type = 'leaf'
        }
      }

      dialogConfig.data.activeItems = { children: treeDataForDeletion, label: ' ' }
    }

    const dialogRef: MatDialogRef<UserJourneyDeleteComponent> = this.dialogService.open(
      UserJourneyDeleteComponent,
      dialogConfig
    )
    dialogRef.componentInstance.getObservable().subscribe((data: any) => {
      if (data.action === TreeNodeEditActions.CLOSE) {
        dialogRef.close()
      }

      if (data.action === TreeNodeEditActions.DELETE) {
        if (data.deletedNodeIds && data.deletedNodeIds.length > 0) {
          // 1. deleting selected nodes from tree data
          this.deleteNodesWithIds(treeData, data.deletedNodeIds, meta)
          createNewCallback(treeData)
        }
        dialogRef.close()
      }
    })
  }

  deleteNodesWithIds(treeData: any, nodeIds: [{ [id: string]: string }], meta: any) {
    for (let i = 0; i < nodeIds.length; i++) {
      const path = toPath(getCurrentPath(treeData, meta.idKey, nodeIds[i].id))
      deleteObjectAtPath(treeData, path)
    }
  }
}
