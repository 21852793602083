import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'digital-platform-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  accessDenied: boolean;
  generalError: boolean;
  importError: boolean;
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    if (this.data.status === 403) {
      this.accessDenied = true;
    } else if (this.data.status === 400 && this.data.message === 'Not-published-version') {
      this.importError = true;
    }
    else {
      this.generalError = true;
    }
  }

}
