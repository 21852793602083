<div style="display: flex; align-items: center; justify-content: flex-start">
  <mat-label *ngIf="!field.props?.omitControlLabel" style="font-weight: 700">{{ field.props?.controlLabel }}</mat-label>
  <section class="example-section">
    <mat-slide-toggle
      fisStyle
      rufId
      [ngClass]="{ 'label-horizontal-gap': !field.props?.label }"
      class="label-vertical-gap"
      [color]="color"
      [checked]="isChecked()"
      [formlyAttributes]="field"
      (change)="changeToggleval($event)"
      [disabled]="field.props?.disabled"
      >{{ (showValue() ? field.formControl.value: '') | titlecase }}
    </mat-slide-toggle>
  </section>
</div>
