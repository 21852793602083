import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { LeaveHomeConfirmationComponent } from '../components/leave-home-confimration/leave-home-confimration.component';

@Injectable()
export class HomeComponentDeactivateGuard implements CanDeactivate<LeaveHomeConfirmationComponent> {
  async canDeactivate(component: LeaveHomeConfirmationComponent): Promise<boolean | UrlTree> {
    const r = await component.canDeactivate()
    return r
  }
}