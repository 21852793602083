import { Directionality } from '@angular/cdk/bidi';
import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@ruf/idp-auth';
import { CookieService } from 'ngx-cookie-service';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ErrorDialogComponent } from '../common/components/dialogs/error-dialog/error-dialog.component';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    errorMessage: any;
    errorStatus: any;
    cookieValue;
    constructor(public dialog: MatDialog, private cookieService: CookieService, private _dir: Directionality, private idpService: AuthService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // To get all browser cookies and delete them.
                    const domain = 'fisglobal.com';// Specified domain name to delete cookies based on it.
                    this.cookieService.getAll();
                    this.cookieService.deleteAll('/', domain);

                    this.idpService.login()
                } else if (error.status === HttpStatusCode.NotFound) {
                    console.error(error)
                    this.errorMessage = error.error.message;
                } else if (error.error.statusCode === 400 && error.error.message === 'Not-published-version') {
                    this.errorStatus = error.status;
                    this.errorMessage = error.error.message;
                    this.openDialog();
                }
                else if (error.error.statusCode === 503 || error.status === 503) {
                    console.log('Request taking too much time to complete');
                }
                else if (error.error instanceof ErrorEvent) {
                    // client-side error
                    this.errorStatus = error.status;
                    this.errorMessage = error.error.message;
                    console.log(this.errorMessage);
                    this.openDialog();
                } else if (error.status === HttpStatusCode.Conflict) {
                    this.errorMessage = error.error.message;
                } else {
                    // server-side error
                    this.errorStatus = error.status;
                    this.errorMessage = error.error.message || error.error.error;
                    console.log(this.errorMessage);
                    this.openDialog();
                }
                return throwError(() => new Error(this.errorMessage));

            })
        );
    }
    openDialog() {
        const data = { 'status': this.errorStatus, 'message': this.errorMessage };
        this.dialog.open(ErrorDialogComponent, {
            role: 'dialog',
            direction: this._dir.value,
            data: data,
            panelClass: ['fis-style']
        });
    }


}
