import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core'
import { find, last, size } from 'lodash'

const DEFAULT_ANIMATION_DURATION = 200
const DEFAULT_CONTAINER_WIDTH = 320
const DEFAULT_CONTAINER_HEIGHT = 600
const DEFAULT_ITEM_HEIGHT = 50

@Component({
  selector: 'digital-platform-draggable-treeview',
  templateUrl: './draggable-treeview.component.html',
  styleUrls: ['./draggable-treeview.component.scss'],
})
export class DraggableTreeviewComponent implements OnChanges {
  @Input()
  animationDuration = DEFAULT_ANIMATION_DURATION
  @Input()
  width = DEFAULT_CONTAINER_WIDTH
  @Input()
  height = DEFAULT_CONTAINER_HEIGHT
  @Input()
  itemHeight = DEFAULT_ITEM_HEIGHT
  @Input()
  items: any[]
  @Input()
  reordable = true
  @Input()
  class: string

  @Output()
  itemSelected: EventEmitter<any> = new EventEmitter()
  @Output()
  itemDropped: EventEmitter<any> = new EventEmitter()

  currentItems: any[]
  parent: any
  selectedIndex: number
  selectedItem: any
  itemsPathStack: any[] = []
  selectParent = false

  @ViewChild('animationDescriptor')
  mainContainer: ElementRef
  next: any = {}
  previous: any = {}

  current = this

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      if (this.itemsPathStack.length > 0) {
        let newItems = this.items
        for (let i = 0; i < this.itemsPathStack.length; i++) {
          const path = this.itemsPathStack[i].path
          this.itemsPathStack[i] = find(newItems, { path })
          newItems = this.itemsPathStack[i].children
        }
        this.currentItems = this.itemsPathStack[this.itemsPathStack.length - 1].children
      } else {
        this.currentItems = this.items
      }
    }
  }

  reset(): void {
    this.setNext({})
    this.setPrevious({})
    this.selectParent = false
    this.selectedIndex = -1
    this.selectedItem = undefined
    this.itemsPathStack = []
    this.parent = undefined
  }

  drop(event: any) {
    // @ts-ignore
    this.itemDropped.emit({
      previous: {
        index: event.previousIndex,
        parent: this.parent
      },
      current: {
        index: event.currentIndex,
        parent: this.parent
      },
      item: event.item.data
    })
    this.selectedIndex = event.currentIndex
    this.selectParent = false
  }

  itemClick(item: any, idx: number): void {
    this.selectedItem = item
    this.selectParent = false

    if (this.hasChildren(item)) {
      this.selectedIndex = -1
      this.setNext(item)
      this.mainContainer.nativeElement.classList.add('animation-descriptor')
      this.mainContainer.nativeElement.classList.add('move-left')
      setTimeout(() => {
        this.mainContainer.nativeElement.classList.remove('animation-descriptor')
        this.mainContainer.nativeElement.classList.remove('move-left')

        this.setCurrent(item)
        this.itemsPathStack.push(item)
        this.selectParent = true
        this.itemSelected.emit({ data: item, activeItems: this.itemsPathStack })
      }, this.animationDuration)
    } else {
      this.selectedIndex = idx
      this.itemSelected.emit({ data: item, activeItems: this.itemsPathStack })
    }

  }

  parentClick(parent: any): void {
    this.itemsPathStack.pop()
    const previousParent = last(this.itemsPathStack)
    this.currentItems = this.parent?.children || this.items

    this.setPrevious(previousParent)
    this.mainContainer.nativeElement.classList.add('animation-descriptor')
    this.mainContainer.nativeElement.classList.add('move-right')

    setTimeout(() => {
      this.mainContainer.nativeElement.classList.remove('animation-descriptor')
      this.mainContainer.nativeElement.classList.remove('move-right')
      this.setCurrent(previousParent)
      this.selectParent = true
      this.itemSelected.emit({ data: previousParent, activeItems: this.itemsPathStack })
    }, this.animationDuration)
  }

  setNext(item: any): void {
    this.setTurn(this.next, item)
  }

  setPrevious(item: any): void {
    this.setTurn(this.previous, item)
  }

  setCurrent(item: any): void {
    this.setTurn(this, item)
  }

  setTurn(where: any, item: any): void {
    where.parent = item
    where.currentItems = item?.children || this.items
    where.selectedIndex = -1
  }

  hasChildren(item: any): boolean {
    return size(item.children) > 0
  }
}