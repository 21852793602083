import { NgModule } from '@angular/core'
import { RufIconModule } from '@ruf/shell'
import { FormlyTemplatesModule } from './formly-templates/formly-templates.module'

@NgModule({
  declarations: [],
  imports: [FormlyTemplatesModule, RufIconModule],
  exports: [FormlyTemplatesModule],
})
export class CustomTemplatesFormlyModule {}
