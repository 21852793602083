import { ComponentType } from '@angular/cdk/portal'
import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialogService: MatDialog) {}

  open<T>(component: ComponentType<T>, options: MatDialogConfig): MatDialogRef<T> {
    const dialogRef = this.dialogService.open(component, options)

    return dialogRef
  }
}
