import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SpinnerOverlayComponent } from './spinner-overlay.component';
import { SpinnerOverlayRef } from './spinner-overlay.ref';

interface SpinnerOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
}

const DEFAULT_CONFIG: SpinnerOverlayConfig = {
  hasBackdrop: true,
  backdropClass: 'admin-ui-spinner-backdrop',
  panelClass: 'admin-ui-spinner-overlay',
};

@Injectable()
export class SpinnerService {
  constructor(private overlay: Overlay) { }

  private getOverlayConfig(config: SpinnerOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    return overlayConfig;
  }

  private createOverlay(config: SpinnerOverlayConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  open(config: SpinnerOverlayConfig = {}) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    const overlayRef = this.createOverlay(dialogConfig);

    const SpinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
    overlayRef.attach(SpinnerOverlayPortal);

    const spinnerOverlayRef = new SpinnerOverlayRef(overlayRef);

    return spinnerOverlayRef;
  }
}
