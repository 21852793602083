import { Component, OnInit } from '@angular/core'
import { AdminuiHomeComponent } from '../adminui-home.component'
import { findIndex, isEmpty } from 'lodash'
import { ModuleConfigService } from '../../common/services/module-config.service'
import { ModuleNames } from '../../common/model/constants'

const LOCAL_STORATE_KEY = 'admin-ui-home-favorites'

@Component({
  selector: 'digital-platform-hexagonal-cards-home-component',
  templateUrl: './hexagonal-cards-home.component.html',
  styleUrls: ['./hexagonal-cards-home.component.scss'],
})
export class HexagonalCardsHomeComponent extends AdminuiHomeComponent implements OnInit {
  favorites: string[]

  ngOnInit(): void {
    this.favorites = this.loadFavsFromLocalStorage()
  }

  isFavorite(modulePrefix: string): boolean {
    return this.favorites.includes(modulePrefix)
  }

  loadFavsFromLocalStorage(): string[] {
    const storedFavs = localStorage.getItem(LOCAL_STORATE_KEY)
    let favsObj = undefined
    if (!isEmpty(storedFavs)) {
      try {
        favsObj = JSON.parse(storedFavs)
      } catch (e) {
        localStorage.removeItem(LOCAL_STORATE_KEY)
      }
    }

    return favsObj || []
  }

  toggleFavorites(modulePrefix: string): void {
    const favsObj = this.loadFavsFromLocalStorage()

    if (favsObj.includes(modulePrefix)) {
      const idx = findIndex(favsObj, (item: string) => item === modulePrefix)
      favsObj.splice(idx, 1)
    } else {
      favsObj.push(modulePrefix)
    }

    this.favorites = favsObj
    localStorage.setItem(LOCAL_STORATE_KEY, JSON.stringify(favsObj))
  }

  getModules(): ModuleConfigService[] {
    const initialLoadedModules = this.getOrderedLoadedModules() || []
    this.favorites = this.loadFavsFromLocalStorage()
    const loadedModules = initialLoadedModules?.filter((module: ModuleConfigService) => ![ModuleNames.CLEAR_CACHE, ModuleNames.TOUR_GOUDE].includes(module?.getModulePrefix()))

    loadedModules.sort((a: ModuleConfigService, b: ModuleConfigService) => {
      if (this.favorites.includes(a.getModulePrefix()) && this.favorites.includes(b.getModulePrefix())) {
        return 0
      }

      if (this.favorites.includes(a.getModulePrefix())) {
        return -1
      }

      return 1
    })

    return loadedModules
  }
}
