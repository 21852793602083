<div [class]="'draggable-treeveiew-container ' + class" style="--animation-duration: {{ animationDuration }}ms; --main-container-width: {{ width }}px; 
  --main-container-height: {{ height }}px; --item-height: {{ itemHeight }}px;">
  <div #animationDescriptor class="animation-container animation-descriptor">

    <ng-container [ngTemplateOutlet]="mainTree" [ngTemplateOutletContext]="{item: previous}">
    </ng-container>

    <ng-container [ngTemplateOutlet]="mainTree" [ngTemplateOutletContext]="{item: current}">
    </ng-container>

    <ng-container [ngTemplateOutlet]="mainTree" [ngTemplateOutletContext]="{item: next}">
    </ng-container>


    <ng-template #mainTree let-obj="item">
      <div #mainContainer class="treeview-container">
        <div *ngIf="obj?.parent" class="treeview-list-item header" (click)="parentClick(obj.parent)"
          [ngClass]="{'active': selectParent}">
          <mat-icon fisStyle fontSet="fisfont" fontIcon="fis-icon-chevron"
            class="treeview-chevron-icon chavron-left"></mat-icon>
          <span class="title">{{ obj.parent.label }}</span>
        </div>

        <div cdkDropList #myList="cdkDropList" class="treeview-list" (cdkDropListDropped)="drop($event)"
          (dragStart)="dragStart()">
          <ng-container *ngFor="let item of obj?.currentItems; let idx = index">
            <div class="treeview-list-item" cdkDrag [cdkDragData]="item" (click)="itemClick(item, idx)"
              [ngClass]="{'active': idx === selectedIndex}" [cdkDragDisabled]="!reordable" [class]="item.class">
              <div class="treeview-list-item-placeholder" *cdkDragPlaceholder>{{ item.label }}</div>
              <div class="treeview-list-item-preview" *cdkDragPreview></div>
              <span class="title">{{ item.label }}</span>
              <mat-icon *ngIf="hasChildren(item)" fisStyle fontSet="fisfont" fontIcon="fis-icon-chevron"
                class="treeview-chevron-icon chavron-right"></mat-icon>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </div>
</div>