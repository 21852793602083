export const UserRoles = {
  GSC_ADMINUI_USER: 'GSC_ADMINUI_USER',
  GSC_ADMINUI_APPROVER: 'GSC_ADMINUI_APPROVER',
  GSC_ADMINUI_PUBLISHER: 'GSC_ADMINUI_PUBLISHER',
  GSC_ADMINUI_MODULE_GBT_USER: 'GSC_ADMINUI_MODULE_GBT_USER',
  GSC_ADMINUI_MODULE_RESB_USER: 'GSC_ADMINUI_MODULE_RESB_USER',
  GSC_ADMINUI_MODULE_VERSIONLOG_USER: 'GSC_ADMINUI_MODULE_VERSIONLOG_USER',
  GSC_ADMINUI_WEBHOOK_CONFIG: 'GSC_ADMINUI_WEBHOOK_CONFIG',
  GSC_ADMINUI_GENERAL_CONFIG: "GSC_ADMINUI_GENERAL_CONFIG"
}
