import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackbarComponent } from './common/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  colorCodeData = [];
  allSlugData = [];

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  constructor(private snackBar: MatSnackBar) { }

  showMessage(data: any, panelClasses: string[]) {
    const config = new MatSnackBarConfig();
    config.duration = 6000;
    config.data = data;
    config.panelClass = panelClasses;
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    this.snackBar.openFromComponent(SnackbarComponent, config);
  }

  showSuccessMessage(data) {
    this.showMessage({ message: data, icon: 'posak-solid', color: 'primary' }, ['fis-style', 'ruf-success', 'ruf-theme-roller-elem', 'snackbar-delete-success'])
  }

  showErrorMessage(data) {
    this.showMessage({ message: data, icon: 'error-solid', color: 'error' }, ['fis-style', 'ruf-error', 'ruf-theme-roller-elem', 'snackbar-delete-error'])
  }

  showWarningMessage(data) {
    this.showMessage({ message: data, icon: 'posak-solid', color: 'warn' }, ['fis-style', 'ruf-warn', 'ruf-theme-roller-elem', 'snackbar-delete-warn'])
  }
}
